import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ImgCarousel } from "./ImgCarousel";

const Container = styled.div.attrs({ className: "products__product-images" })`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  /* align-items: center; */
  max-width: 500px;
  padding: 20px;
`;

const CoverImgContainer = styled.div`
  width: 100%;
  padding: 0px 40px;
  box-sizing: border-box;
  height: 400px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
`;

const CoverImg = styled.img.attrs(({ src }) => ({
  className: "product__img-cover",
}))`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const geFirstImgSrc = (imgs) => (imgs && imgs.length ? imgs[0]?.src : "");

export const ProductImages = ({ imgs, viewOnly }) => {
  const [currentImg, setCurrentImg] = useState(geFirstImgSrc(imgs));

  useEffect(() => {
    setCurrentImg(geFirstImgSrc(imgs));
    // eslint-disable-next-line
  }, [imgs.length]);

  return (
    <Container>
      <CoverImgContainer>
        {currentImg && <CoverImg src={currentImg} />}
      </CoverImgContainer>
      <ImgCarousel
        imgs={imgs}
        currentImg={currentImg}
        setCurrentImg={setCurrentImg}
        viewOnly={viewOnly}
      />
    </Container>
  );
};
