import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";

import { createGlobalStyle } from "styled-components";
import { colors } from "./styles/colors";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: "Roboto, Titillium Web", sans-serif;
  }

  a {
    text-decoration: none;
  }

  textarea {
    font-family: "Roboto, Titillium Web", sans-serif;
  }

  * {
    &::-webkit-scrollbar {
      width: 15px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: white;
      border-radius: 10px;
    }
  
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: ${colors.grey}; 
      border: 5px solid white;
      border-radius: 10px;
     
    }
  }

  
`;

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
