import React, { useState } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { PageTitle } from "../../components/PageTitle";
import { Page } from "../Page";

import { colors } from "../../styles/colors";

import { TextField } from "../../components/Inputs/TextField";
import { Button } from "../../components/Inputs/Button";
import { login, getUserDetails } from "../../utils/api";
import { actionDispatch } from "../../appState/actions";
import { useAppDispatch } from "../../appState/appState";

const LoginContainer = styled(Page).attrs({ className: "login-container" })`
  display: flex;
  flex-direction: column;
  padding-right: 0;
`;

const FormContainer = styled.div.attrs({ className: "login-form-container" })`
  box-sizing: border-box;
  padding-bottom: 100px;
  padding-left: 100px;
  padding-right: 50px;
  background: ${colors.lightgrey};
  display: flex;
`;

const LoginForm = styled.div.attrs({ className: "login-form" })`
  display: flex;
  flex-direction: column;
  flex: 1;

  .text-field-container {
    margin-bottom: 20px;
  }
`;

const LoginFormTitle = styled.h2.attrs({ className: "login-form-title" })`
  font-size: 40px;
  font-weight: bold;
`;

const ButtonsContainer = styled.div.attrs({
  className: "login-form-buttons-container",
})`
  margin-top: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const RegisterContainer = styled.div.attrs({
  className: "register-container",
})`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${colors.blue};
  margin: 0px 200px;
  align-items: center;
  color: white;

  .button {
    margin: 30px;
  }
`;

const RegisterTitle = styled(LoginFormTitle)``;

const RegisterText = styled.p.attrs({
  className: "register-text",
})`
  margin: 0;
  margin-bottom: 10px;
  font-weight: ${({ bold }) => (bold ? "bold" : "initial")};
`;

const handleLogin =
  (email, password, history, setLoginFailed, dispatch) => async () => {
    dispatch(actionDispatch.loading.setVisibility(true));
    try {
      await login(email, password);
      const userData = await getUserDetails();
      dispatch(actionDispatch.user.setData(userData));
      history.push("/");
      dispatch(actionDispatch.loading.setVisibility(false));
    } catch (error) {
      console.log(error);
      dispatch(actionDispatch.loading.setVisibility(false));
      setLoginFailed(true);
    }
  };

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginFailed, setLoginFailed] = useState(false);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const onLogin = handleLogin(
    email,
    password,
    history,
    setLoginFailed,
    dispatch
  );

  return (
    <LoginContainer>
      <PageTitle>Salut!</PageTitle>
      <FormContainer>
        <LoginForm>
          <LoginFormTitle>Autentificare</LoginFormTitle>
          <TextField
            label="Email"
            onChange={(value) => {
              setEmail(value);
              setLoginFailed(false);
            }}
            value={email}
            invalid={loginFailed}
          />
          <TextField
            label="Parola"
            type="password"
            onChange={(value) => {
              setPassword(value);
              setLoginFailed(false);
            }}
            value={password}
            invalid={loginFailed}
            onEnter={onLogin}
          />
          <ButtonsContainer>
            <Button text="Intra in Cont" onClick={onLogin} />
            {/* <TextButton>Ti-ai uitat parola?</TextButton> */}
          </ButtonsContainer>
        </LoginForm>
        <RegisterContainer>
          <RegisterTitle>Client nou?</RegisterTitle>
          <RegisterText bold>
            Creaza-ti un cont nou si veri avea parte de
          </RegisterText>
          <RegisterText>Check out faster</RegisterText>
          <RegisterText>Save multiple shipping addresses</RegisterText>
          <RegisterText>Access your order history</RegisterText>
          <RegisterText>Track new orders</RegisterText>
          <Link to="/auth/register">
            <Button text="Creaza un cont" />
          </Link>
        </RegisterContainer>
      </FormContainer>
    </LoginContainer>
  );
};
