import React, { useState } from "react";
import styled from "styled-components";
import { isEmpty, omit } from "lodash";
import { PageTitle } from "../../components/PageTitle";

import { TextField } from "../../components/Inputs/TextField";
import { Checkbox } from "../../components/Inputs/Checkbox";
import { Button } from "../../components/Inputs/Button";
import { BackButton } from "../../components/BackButton";
import { Page } from "../Page";
import { getUserDetails, registerUser } from "../../utils/api";
import { actionDispatch } from "../../appState/actions";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../appState/appState";

const RegisterContainer = styled(Page).attrs({
  className: "register-container",
})`
  .back-button {
    margin-bottom: 30px;
  }
`;

const FieldContainer = styled.div.attrs({ className: "field-container" })`
  display: flex;

  .text-field-container {
    margin: 0px 10px 20px 10px;
  }
`;

const BlankContainer = styled.div.attrs({ className: "blank-container" })`
  flex: 1;
`;

const ActionsContainer = styled.div.attrs({ className: "actions-container" })`
  flex: 1;
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
`;

const getInvalidFields = (fields) => {
  const invalidFields = {};
  if (fields.password && fields.confirmPassword) {
    if (fields.password !== fields.confirmPassword) {
      invalidFields.password = true;
      invalidFields.confirmPassword = true;
    }
  }

  for (const fieldName in fields) {
    const fieldValue = fields[fieldName];
    if (!fieldValue) invalidFields[fieldName] = true;
  }

  return invalidFields;
};

const callRegisterApi = async (fields) => {
  const {
    email,
    password,
    firstName,
    lastName,
    country,
    county,
    city,
    address,
    zip,
    mobile,
  } = fields;

  const payload = {
    email,
    password,
    firstName,
    lastName,
    country,
    county,
    city,
    address,
    zipCode: zip,
    phone: mobile,
  };

  await registerUser(payload);
};

const handleRegister =
  (fields, setInValidFields, dispatch, history) => async () => {
    setInValidFields({});

    const invalidFields = getInvalidFields(fields);
    if (isEmpty(invalidFields)) {
      dispatch(actionDispatch.loading.setVisibility(true));
      try {
        await callRegisterApi(fields);
        await getUserDetails();
        history.push("/");
        dispatch(actionDispatch.loading.setVisibility(false));
      } catch (error) {
        console.log(error);
        dispatch(actionDispatch.loading.setVisibility(false));
        dispatch(
          actionDispatch.notifications.setMessage(
            "Actiunea nu a fost realizata cu succes!"
          )
        );
      }
    } else {
      setInValidFields(invalidFields);
    }
  };

export const Register = () => {
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [country, setCountry] = useState("Romania");
  const [county, setCounty] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [zip, setZip] = useState("");
  const [mobile, setMobile] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [inValidFields, setInValidFields] = useState({});

  const history = useHistory();
  const dispatch = useAppDispatch();

  const fields = {
    email,
    lastName,
    firstName,
    password,
    confirmPassword,
    country,
    county,
    city,
    address,
    zip,
    mobile,
    terms: termsAccepted,
  };

  const onRegister = handleRegister(
    fields,
    setInValidFields,
    dispatch,
    history
  );

  return (
    <RegisterContainer>
      <BackButton path="/auth/login">Inpoi la autentificare</BackButton>
      <PageTitle>Ne bucuram sa te cunoastem</PageTitle>
      <FieldContainer>
        <TextField
          label="Email"
          onChange={(value) => {
            setEmail(value);
            if (value) setInValidFields(omit(inValidFields, ["email"]));
          }}
          value={email}
          invalid={inValidFields.email}
        />
      </FieldContainer>
      <FieldContainer>
        <TextField
          label="Nume"
          onChange={(value) => {
            setLastName(value);
            if (value) setInValidFields(omit(inValidFields, ["lastName"]));
          }}
          value={lastName}
          invalid={inValidFields.lastName}
        />
        <TextField
          label="Prenume"
          onChange={(value) => {
            setFirstName(value);
            if (value) setInValidFields(omit(inValidFields, ["firstName"]));
          }}
          value={firstName}
          invalid={inValidFields.firstName}
        />
      </FieldContainer>
      <FieldContainer>
        <TextField
          label="Parola"
          onChange={(value) => {
            setPassword(value);
            if (value) setInValidFields(omit(inValidFields, ["password"]));
          }}
          value={password}
          invalid={inValidFields.password}
          type="password"
        />
        <TextField
          label="Confirmare Parola"
          onChange={(value) => {
            setConfirmPassword(value);
            if (value)
              setInValidFields(omit(inValidFields, ["confirmPassword"]));
          }}
          value={confirmPassword}
          invalid={inValidFields.confirmPassword}
          type="password"
        />
      </FieldContainer>
      <FieldContainer>
        <TextField
          label="Tara"
          onChange={(value) => {
            setCountry(value);
            if (value) setInValidFields(omit(inValidFields, ["country"]));
          }}
          value={country}
          invalid={inValidFields.country}
        />
        <TextField
          label="Judet"
          onChange={(value) => {
            setCounty(value);
            if (value) setInValidFields(omit(inValidFields, ["county"]));
          }}
          value={county}
          invalid={inValidFields.county}
        />
      </FieldContainer>
      <FieldContainer>
        <TextField
          label="Oras"
          onChange={(value) => {
            setCity(value);
            if (value) setInValidFields(omit(inValidFields, ["city"]));
          }}
          value={city}
          invalid={inValidFields.city}
        />
        <TextField
          label="Adresa"
          onChange={(value) => {
            setAddress(value);
            if (value) setInValidFields(omit(inValidFields, ["address"]));
          }}
          value={address}
          invalid={inValidFields.address}
        />
      </FieldContainer>
      <FieldContainer>
        <TextField
          label="Cod Postal"
          onChange={(value) => {
            setZip(value);
            if (value) setInValidFields(omit(inValidFields, ["zip"]));
          }}
          value={zip}
          invalid={inValidFields.zip}
        />
        <TextField
          label="Nr de Telefon"
          onChange={(value) => {
            setMobile(value);
            if (value) setInValidFields(omit(inValidFields, ["mobile"]));
          }}
          value={mobile}
          invalid={inValidFields.mobile}
        />
      </FieldContainer>
      <FieldContainer />
      <FieldContainer>
        <BlankContainer />
        <ActionsContainer>
          <Checkbox
            value={termsAccepted}
            onChange={(value) => setTermsAccepted(value)}
            label="Sunt de acord cu Termenii si Conditiile"
            invalid={inValidFields.terms}
          />
          <Button
            onClick={onRegister}
            text="Creaza Cont"
            disabled={!termsAccepted}
          />
        </ActionsContainer>
      </FieldContainer>
    </RegisterContainer>
  );
};
