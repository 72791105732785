import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  position: relative;
`;

const Title = styled.h1`
  text-align: center;
  color: white;
  margin: 50px 0px;
  font-size: 60px;
`;

const Cards = styled.div`
  width: 100%;
  max-width: 1060px;
  padding: 10px;
  display: flex;
  gap: 20px;
`;

const Card = styled.div`
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  background: white;
  flex: 1;
`;

const CardTitle = styled.h3`
  background: #008e95;
  color: white;
  text-align: center;
  padding: 15px 0px;
  margin: 25px 0px;
`;
const CartSubTitle = styled.h3`
  text-align: center;
`;

const CartText = styled.p`
  text-align: center;
  padding: 0px 20px;
`;

const ParallaxContainer = styled.div`
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: -50%;
  left: 0;
  background-image: url("/img/parallax-banner.png");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const cardsData = [
  {
    type: "LIVRARE",
    price: "In Romania",
    description: "Livrare standard prin curier",
  },
  {
    type: "CONTACT",
    price: "Colinita Ovidiu",
    description: `
      Tel: 0742389898
      Email: ayotek.service@gmail.com
      Adresa: Floresti, Urusagului, 19A, Cluj`,
  },
  {
    type: "PLATA",
    price: "Online/Ramburs",
    description: `Transfer bancar in contul nostru (BT sau Trezorerie)
      Ramburs curierului la primirea coletului`,
  },
];

export const OrderCards = () => (
  <Cards>
    {cardsData.map(({ type, price, description }) => (
      <Card key={type}>
        <CardTitle>{type}</CardTitle>
        <CartSubTitle>{price}</CartSubTitle>
        {description.split("\n").map((p, index) => (
          <CartText key={index}>{p}</CartText>
        ))}
      </Card>
    ))}
  </Cards>
);

export const OrderProcess = () => (
  <Container>
    <ParallaxContainer />
    <Title>Ne ocupam noi de tot</Title>
    <OrderCards />
  </Container>
);
