import React, { useContext, useReducer, createContext } from "react";
import { handleRequestProducts } from "./helpers";
import { loadingInitialState, reducers } from "./reducers";

const AppContext = createContext();
const AppContextDispatch = createContext();
const RequestProductsContext = createContext();

export const initialState = {
  loading: loadingInitialState,
  notifications: {
    message: "",
    messageId: 0,
  },
  user: {},
  productsFilters: {
    category: [
      {
        id: 1,
        label: "Laptopuri",
        value: false,
      },
      {
        id: 2,
        label: "PC's",
        value: false,
      },
      {
        id: 3,
        label: "Componente",
        value: false,
      },
      {
        id: 4,
        label: "Imprimante",
        value: false,
      },
      {
        id: "others",
        label: "ALTELE",
        value: false,
      },
    ],
    type: [
      {
        id: 1,
        label: "Noi",
        value: false,
      },
      {
        id: 2,
        label: "Reconditionate",
        value: false,
      },
    ],
    brand: [
      {
        id: 1,
        label: "Asus",
        value: false,
      },
      {
        id: 2,
        label: "HP",
        value: false,
      },
      {
        id: 3,
        label: "Mac",
        value: false,
      },
      {
        id: 4,
        label: "Dell",
        value: false,
      },
    ],
    price: [
      {
        id: 1,
        label: "sub 100",
        value: false,
      },
      {
        id: 2,
        label: "100 - 500",
        value: false,
      },
      {
        id: 3,
        label: "500 - 1000",
        value: false,
      },
      {
        id: 4,
        label: "1000 - 1500",
        value: false,
      },
      {
        id: 5,
        label: "1500 - 2500",
        value: false,
      },
      {
        id: 6,
        label: "2500 - 5000",
        value: false,
      },
      {
        id: 7,
        label: "peste 5000",
        value: false,
      },
    ],
    favorite: false,
  },
  productsSort: {
    type: null,
    direction: null,
  },
  products: [],
  lastProductItem: null,
  editProduct: {
    id: null,
    name: "",
    description: "",
    promoProduct: false,
    suggestedProductIndex: -1,
    category: null,
    brand: null,
    stockStatus: null,
    stockValue: "",
    price: "",
    discount: "",
    warranty: "",
    specs: [],
    imgs: [],
  },
  cart: {
    items:
      (localStorage.getItem("ayo-cart") &&
        JSON.parse(localStorage.getItem("ayo-cart"))) ||
      [],
    visibility: false,
  },
};

export const useAppState = () => useContext(AppContext);
export const useAppDispatch = () => useContext(AppContextDispatch);
export const useRequestProducts = () => useContext(RequestProductsContext);

export const AppStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, initialState);
  const requestProducts = handleRequestProducts(state, dispatch);

  return (
    <AppContext.Provider value={state}>
      <AppContextDispatch.Provider value={dispatch}>
        <RequestProductsContext.Provider value={requestProducts}>
          {children}
        </RequestProductsContext.Provider>
      </AppContextDispatch.Provider>
    </AppContext.Provider>
  );
};
