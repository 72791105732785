import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { actionDispatch } from "../../../appState/actions";
import { useAppDispatch, useAppState } from "../../../appState/appState";
import { Button } from "../../../components/Inputs/Button";
import { addProduct, editProduct } from "../../../utils";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;

  .button {
    margin-left: 20px;
  }
`;

const useHandleCancel = () => {
  const history = useHistory();

  return () => {
    history.push("/produse");
  };
};

const useHandleOnAddProduct = () => {
  const data = useAppState().editProduct;
  const dispatch = useAppDispatch();
  const history = useHistory();

  return () => {
    dispatch(actionDispatch.loading.setVisibility(true));
    addProduct(data)
      .then(() => {
        dispatch(actionDispatch.loading.setVisibility(false));
        history.push("/produse");
      })
      .catch(() => {
        dispatch(actionDispatch.loading.setVisibility(false));
        dispatch(
          actionDispatch.notifications.setMessage(
            "Actiunea nu a fost realizata cu succes!"
          )
        );
      });
  };
};

const useHandleOnEditProduct = () => {
  const data = useAppState().editProduct;
  const dispatch = useAppDispatch();

  return () => {
    dispatch(actionDispatch.loading.setVisibility(true));
    editProduct(data)
      .then(() => dispatch(actionDispatch.loading.setVisibility(false)))
      .catch(() => {
        dispatch(actionDispatch.loading.setVisibility(false));
        dispatch(
          actionDispatch.notifications.setMessage(
            "Actiunea nu a fost realizata cu succes!"
          )
        );
      });
  };
};

const useIsNewProduct = () => {
  const { id } = useAppState().editProduct;

  if (id) {
    return false;
  }

  return true;
};

export const EditProductsAction = () => {
  const isNewProduct = useIsNewProduct();
  const onCancel = useHandleCancel();
  const onAddProduct = useHandleOnAddProduct();
  const onEditProduct = useHandleOnEditProduct();

  return (
    <Container>
      <Button text="Anuleaza" secondary onClick={onCancel} />
      <Button
        text={isNewProduct ? "Adauga Produs" : "Publica Schimbari"}
        onClick={isNewProduct ? onAddProduct : onEditProduct}
      />
    </Container>
  );
};
