import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { actionDispatch } from "../../../appState/actions";
import { useAppDispatch, useAppState } from "../../../appState/appState";
import { getFiltersByType, getProduct } from "../../../utils";

export const useGetProductData = (addProducts) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const editProduct = useAppState().editProduct;
  const productAttributes = Object.keys(editProduct);

  useEffect(() => {
    if (id && !editProduct.id && !addProducts) {
      dispatch(actionDispatch.loading.setVisibility(true));
      getProduct(id)
        .then((product) => {
          const editProductState = productAttributes.reduce(
            (acc, attributeName) => {
              acc[attributeName] = product[attributeName];

              return acc;
            },
            {}
          );
          dispatch(actionDispatch.editProduct.setEditValues(editProductState));
          dispatch(actionDispatch.loading.setVisibility(false));
        })
        .catch(() => {
          dispatch(actionDispatch.loading.setVisibility(false));
          dispatch(
            actionDispatch.notifications.setMessage("Produsul nu a fost gasit")
          );
        });
    }

    // eslint-disable-next-line
  }, []);
};

export const useGetCategoryOptions = () => {
  const [categoryOptions, setCategoryOptions] = useState([]);

  useEffect(() => {
    getFiltersByType("category").then((data) => {
      const { filters } = data || {};

      if (filters && filters.length) {
        setCategoryOptions(filters.map(({ value }) => value));
      }
    });
  }, []);

  return { categoryOptions };
};

export const useGetBrandOptions = () => {
  const [brandOptions, setBrandOptions] = useState([]);

  useEffect(() => {
    getFiltersByType("brand").then((data) => {
      const { filters } = data || {};

      if (filters && filters.length) {
        setBrandOptions(filters.map(({ value }) => value));
      }
    });
  }, []);

  return { brandOptions };
};
