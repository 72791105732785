import { useEffect, useState } from "react";
import { actionDispatch } from "../../appState/actions";
import { useAppDispatch } from "../../appState/appState";
import { getPromoProduct, getSuggestedProducts } from "../../utils";

export const useGetSuggestedProductData = () => {
  const dispatch = useAppDispatch();
  const [suggestedProducts, setSuggestedProducts] = useState([]);

  useEffect(() => {
    getSuggestedProducts()
      .then((suggestedProducts) => {
        setSuggestedProducts(suggestedProducts);
      })
      .catch(() => {
        dispatch(
          actionDispatch.notifications.setMessage(
            "Produse sugerated indisponibil"
          )
        );
      });
    // eslint-disable-next-line
  }, []);

  return suggestedProducts;
};

export const useGetPromoProductData = () => {
  const dispatch = useAppDispatch();
  const [promoProduct, setPromoProduct] = useState({});

  useEffect(() => {
    getPromoProduct()
      .then((promoProduct) => {
        setPromoProduct(promoProduct);
      })
      .catch(() => {
        dispatch(
          actionDispatch.notifications.setMessage(
            "Produse sugerated indisponibil"
          )
        );
      });
    // eslint-disable-next-line
  }, []);

  return promoProduct;
};
