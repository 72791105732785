import React from "react";
import styled from "styled-components";
import { actionDispatch } from "../../appState/actions";
import { useAppDispatch } from "../../appState/appState";
import { TextButton } from "../Inputs/TextButton";

const Button = styled(TextButton).attrs({ className: "filters__reset-button" })`
  padding: 20px;
  padding-left: 30px;
`;

export const ResetFiltersButton = () => {
  const dispatch = useAppDispatch();

  return (
    <Button onClick={() => dispatch(actionDispatch.productsFilters.reset())}>
      reseteaza filtrele
    </Button>
  );
};
