import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { Login } from "./Login";
import { Register } from "./Register";
import { Profile } from "./Profile/Profile";

import { isEmpty } from "lodash";
import { useAppState } from "../../appState/appState";

export const Auth = () => {
  const userLogged = !isEmpty(useAppState().user);

  return (
    <Switch>
      <Route exact path="/auth">
        <Redirect to={userLogged ? "/auth/profile" : "/auth/login"} />
      </Route>
      <Route path="/auth/login">
        <Login />
      </Route>
      <Route path="/auth/register">
        <Register />
      </Route>
      <Route path="/auth/profile">
        <Profile />
      </Route>
    </Switch>
  );
};
