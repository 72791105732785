export const icons = {
  menu: "/svg/icons/menu.svg",
  logo: "/svg/icons/logo.svg",
  search: "/svg/icons/search.svg",
  profile: "/svg/icons/profile.svg",
  profile_selected: "/svg/icons/profile-selected.svg",
  profile_logged_in: "/svg/icons/profile-logged-in.svg",
  favorite: "/svg/icons/favorite.svg",
  "favorite-grey": "/svg/icons/favorite-grey.svg",
  "favorite-red": "/svg/icons/favorite-red.svg",
  "favorite-red-dot": "/svg/icons/favorite-red-dot.svg",
  "favorite-full-red-dot": "/svg/icons/favorite-full-red-dot.svg",
  "favorite-full": "/svg/icons/favorite-full.svg",
  "favorite-white-full": "/svg/icons/favorite-white-full.svg",
  "favorite-white": "/svg/icons/favorite-white.svg",
  cart: "/svg/icons/cart.svg",
  "cart-white": "/svg/icons/cart-white.svg",
  back: "/svg/icons/back.svg",
  close: "/svg/icons/close.svg",
  "close-white": "/svg/icons/close-white.svg",
  warning: "/svg/icons/warning.svg",
  orders: "/svg/icons/orders.svg",
  edit: "/svg/icons/edit.svg",
  arrow: "/svg/icons/arrow.svg",
  expand: "/svg/icons/expand.svg",
  add: "/svg/icons/add.svg",
  previous: "/svg/icons/previous.svg",
  next: "/svg/icons/next.svg",
  trash: "/svg/icons/trash.svg",
  summary: "/svg/icons/summary.svg",
  check: "/svg/icons/check.svg",
  happy: "/svg/icons/happy.svg",
};
