import React from "react";
import styled from "styled-components";
import { useAppState } from "../../appState/appState";
import { CartItem } from "./CartItem";
import { colors } from "../../styles/colors";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: ${({ stretch }) => (stretch ? "visible" : "auto")};
  border-bottom: 1px solid ${colors.grey};
`;

export const CartItems = ({ stretch }) => {
  const {
    cart: { items },
  } = useAppState();

  return (
    <Container stretch={stretch}>
      {items.map((item) => (
        <CartItem key={item.id} {...item} />
      ))}
    </Container>
  );
};
