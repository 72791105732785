import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { Page } from "../../Page";
import { PageTitle } from "../../../components/PageTitle";
import { Card } from "../../../components/Card";
import { colors } from "../../../styles/colors";
import { Button } from "../../../components/Inputs/Button";
import { Icon } from "../../../components/Icon/Icon";
import { EditProfileModal } from "./EditProfileModal";
import { useAppDispatch, useAppState } from "../../../appState/appState";
import { actionDispatch } from "../../../appState/actions";

const ProfileContainer = styled(Page).attrs({ className: "profile" })``;

const Section = styled.div.attrs({ className: "profile-section" })`
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  flex-shrink: 0;
`;

const AddressSection = styled(Section)`
  flex: 1;
  flex-direction: column;
  margin: 0;
  &:first-child {
    border-right: 1px solid ${colors.grey};
    margin-right: 40px;
  }
`;

const ButtonsSection = styled(Section)`
  justify-content: space-between;
`;

const CardTitle = styled.h3.attrs({ className: "profile-card-title" })`
  margin: 0;
  margin-bottom: 40px;
`;

const CardSubTitle = styled.h3.attrs({ className: "profile-card-sub-title" })`
  margin: 0;
  margin-bottom: 30px;
  font-weight: normal;
`;

const CardText = styled.p.attrs({ className: "profile-card-text" })`
  margin: 0;
  margin-bottom: 15px;

  span {
    font-weight: bold;
  }

  &:last-child {
    margin-bottom: 0px;
  }
`;

const OrdersCard = styled(Card)`
  background: ${colors.blue};
  display: flex;
  flex-direction: column;
  align-items: center;

  .profile-card-title {
    color: white;
  }

  .profile-card-title {
    font-size: 30px;
  }
`;

const PictogramContainer = styled.div`
  margin-bottom: 20px;
  padding-bottom: 20px;
  width: 60%;
  border-bottom: 1px solid ${colors.lightgrey};
  display: flex;
  justify-content: center;
  position: relative;
`;

const OrdersCount = styled.div.attrs({ className: "user-orders-count" })`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(30px, -70px);
  background: ${colors.green};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;

  p {
    color: white;
    font-weight: bold;
    margin: 0;
    padding-top: 2px;
  }
`;

const ProfilePageTitle = styled(PageTitle)`
  display: flex;
  max-width: unset;
  align-items: flex-end;
  padding-left: unset;

  .icon {
    margin-left: auto;
    margin-right: -10px;
    cursor: pointer;
  }
`;

const handleLogout = (history, dispatch) => () => {
  dispatch(actionDispatch.user.clearData());
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  history.push("/");
};

export const Profile = () => {
  const {
    firstName,
    lastName,
    email,
    phone,
    country,
    county,
    city,
    address,
    zipCode,
  } = useAppState().user;

  const [editModalVisibility, setEditModalVisibility] = useState(false);
  const dispatch = useAppDispatch();

  const history = useHistory();

  const onLogout = handleLogout(history, dispatch);

  return (
    <ProfileContainer>
      <ProfilePageTitle>
        Profilul tau
        <Icon
          icon="edit"
          size={60}
          onClick={() => setEditModalVisibility(true)}
        />
      </ProfilePageTitle>
      <Section>
        <Card marginRight>
          <CardTitle>Datele Contului</CardTitle>
          <CardText>
            <span>Nume: </span>
            {`${firstName} ${lastName}`}
          </CardText>
          <CardText>
            <span>Email: </span>
            {email}
          </CardText>
          <CardText>
            <span>Telefon: </span>
            {phone}
          </CardText>
        </Card>
        <OrdersCard>
          <CardTitle>Activitatea ta</CardTitle>
          <PictogramContainer>
            <Icon icon="orders" size={100} />
            <OrdersCount>
              <p>{0}</p>
            </OrdersCount>
          </PictogramContainer>
          <Button
            onClick={() => history.push("/auth/profile/orders")}
            text="Vezi istoricul comenzilor"
          />
        </OrdersCard>
      </Section>
      <Card color={colors.lightgrey}>
        <CardTitle>Adresele mele</CardTitle>
        <Section>
          <AddressSection>
            <CardSubTitle>Principala</CardSubTitle>
            <CardText>
              <span>Tara: </span>
              {country}
            </CardText>
            <CardText>
              <span>Judet: </span>
              {county}
            </CardText>
            <CardText>
              <span>Oras: </span>
              {city}
            </CardText>
            <CardText>
              <span>Adresa: </span>
              {address}
            </CardText>
            <CardText>
              <span>Cod Postal: </span>
              {zipCode}
            </CardText>
          </AddressSection>
          <AddressSection>
            <CardSubTitle>Secundara</CardSubTitle>
            <CardText>Nu exista adresa secundara</CardText>
          </AddressSection>
        </Section>
      </Card>
      <ButtonsSection>
        <Button secondary text="Reseteaza Parola" />
        <Button secondary text="Delogare" onClick={onLogout} />
        <Button text="Lasa-ne o Parere" />
      </ButtonsSection>
      <EditProfileModal
        visibility={editModalVisibility}
        onClose={() => setEditModalVisibility(false)}
        onSave={() => setEditModalVisibility(false)}
      />
    </ProfileContainer>
  );
};
