import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppState } from "../../appState/appState";
import { PageTitle } from "../../components/PageTitle";
import { colors } from "../../styles/colors";
import { OrderStatus } from "./OrderStatus";
import { isEmpty } from "lodash";
import { Route } from "react-router-dom";
import { OrderDetails } from "./OrderDetails";
import { Button } from "../../components/Inputs/Button";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { OrderSummary } from "./OrderSummary";
import { actionDispatch } from "../../appState/actions";
import { placeOrder } from "../../utils";

const Container = styled.div.attrs({ className: "contact-page" })`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 50px;
`;

const Title = styled(PageTitle)`
  padding-left: 250px;
  margin-bottom: 50px;
`;

const Content = styled.div`
  flex: 1;
  background: ${colors.lightgrey};
  margin-left: 100px;
  padding: 50px;
  border-top-left-radius: 100px;
  display: flex;
  justify-content: center;
`;

const InnerContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1080px;
  margin-right: 100px;
  flex: 1;
  position: relative;
`;

const titleByPath = (path) => {
  switch (path) {
    case "/plasare-comanda/detalii":
      return "Detalii Comanda";
    case "/plasare-comanda/sumar":
      return "Sumar Comanda";

    default:
      return "";
  }
};

const getNextPath = (pathname) => {
  switch (pathname) {
    case "/plasare-comanda/detalii":
      return "/plasare-comanda/sumar";
    case "/plasare-comanda/sumar":
      return "/plasare-comanda/finalizata";

    default:
      return "/";
  }
};

export const PlaceOrder = () => {
  const { pathname } = useLocation();
  const user = useAppState().user;
  const products = useAppState().cart.items;

  const total =
    products.reduce((acc, product) => {
      acc = acc + product.price * product.nrOfItems;

      return acc;
    }, 0) + 20;
  const [orderDetails, setOrderDetails] = useState({
    delivery: {},
    invoice: {},
  });
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isEmpty(user) && isEmpty(orderDetails.delivery)) {
      const { address, email, city, phone } = user;
      setOrderDetails({
        ...orderDetails,
        delivery: { address, email, city, phone },
      });
    }
    // eslint-disable-next-line
  }, [user]);

  const setDeliveryField = (key, value) => {
    setOrderDetails({
      ...orderDetails,
      delivery: {
        ...orderDetails.delivery,
        [key]: value,
      },
    });
  };

  const setInvoiceField = (key, value) => {
    setOrderDetails({
      ...orderDetails,
      invoice: {
        ...orderDetails.invoice,
        [key]: value,
      },
    });
  };

  const nextPath = getNextPath(pathname);
  const nextIsLastStep = nextPath === "/plasare-comanda/finalizata";

  return (
    <Container>
      <Title>{titleByPath(pathname)}</Title>
      <Content>
        <InnerContent>
          <OrderStatus />
          <Route path="/plasare-comanda/detalii">
            <OrderDetails
              setDeliveryField={setDeliveryField}
              setInvoiceField={setInvoiceField}
              orderDetails={orderDetails}
            />
          </Route>
          <Route path="/plasare-comanda/sumar">
            <OrderSummary
              orderDetails={orderDetails}
              products={products}
              total={total}
            />
          </Route>
          <Button
            text={nextIsLastStep ? "Plaseaza Comanda" : "Pasul Urmator"}
            onClick={() => {
              if (nextIsLastStep) {
                placeOrder({
                  details: orderDetails,
                  products,
                  value: total,
                }).then(() => {
                  dispatch(actionDispatch.cart.rest());
                  history.push(nextPath);
                });
              } else {
                history.push(nextPath);
              }
            }}
          />
        </InnerContent>
      </Content>
    </Container>
  );
};
