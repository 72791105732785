import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  useAppDispatch,
  useAppState,
  useRequestProducts,
} from "../../appState/appState";
import { actionDispatch } from "../../appState/actions";
import { SelectedItem } from "./SelectedItem";
import { SortItem } from "./SortItem";
import { colors } from "../../styles/colors";

const Container = styled.div.attrs({ className: "sort-dropdown" })`
  margin-left: auto;
  position: relative;
  padding: 20px;
  padding-bottom: 0px;
  padding-top: 12px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  background: ${({ expanded }) => (expanded ? "white" : "unset")};
`;

const SortItemFlyout = styled.div.attrs({
  className: "sort-dropdown__flyout",
})`
  box-sizing: border-box;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 0px 20px;
  background: white;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 1;
  border-bottom: 1px solid ${colors.lightgrey};
  border-left: 1px solid ${colors.lightgrey};
  border-right: 1px solid ${colors.lightgrey};
`;

const handleOnSortClick = (dispatch, sortType) => (direction) => {
  dispatch(actionDispatch.lastProductItem.uset());
  if (sortType && direction) {
    if (sortType === "price") {
      dispatch(actionDispatch.productsSort.setByPrice(direction));
    } else if (sortType === "date") {
      dispatch(actionDispatch.productsSort.setByDate(direction));
    }
  } else {
    dispatch(actionDispatch.productsSort.reset());
  }
};

export const SortDropdown = () => {
  const [expanded, setExpanded] = useState(false);
  const { type: sortType, direction: sortDirection } =
    useAppState().productsSort;
  const dispatch = useAppDispatch();
  const requestProducts = useRequestProducts();

  const ref = useRef(null);

  useEffect(() => {
    setExpanded(false);
    if (ref.current) {
      requestProducts();
    }
    ref.current = true;
    // eslint-disable-next-line
  }, [sortType, sortDirection]);

  return (
    <Container expanded={expanded}>
      <SelectedItem
        expanded={expanded}
        onClick={() => setExpanded(!expanded)}
        sortType={sortType}
        sortDirection={sortDirection}
      />
      {expanded && (
        <SortItemFlyout>
          {sortType && sortDirection && (
            <SortItem
              label="reseteaza"
              reset
              onClick={handleOnSortClick(dispatch)}
            />
          )}

          <SortItem
            label="PRET"
            onClick={handleOnSortClick(dispatch, "price")}
          />
          <SortItem
            label="DATA"
            onClick={handleOnSortClick(dispatch, "date")}
          />
        </SortItemFlyout>
      )}
    </Container>
  );
};
