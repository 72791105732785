import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { actionDispatch } from "../../appState/actions";
import { useAppDispatch } from "../../appState/appState";
import { colors } from "../../styles/colors";
import { Button } from "../Inputs/Button";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  p {
    color: ${colors.grey};
  }
`;

const useHideCart = () => {
  const dispatch = useAppDispatch();

  return () => dispatch(actionDispatch.cart.setVisibility(false));
};

export const PlaceOrderButton = () => {
  const history = useHistory();
  const onHideCart = useHideCart();

  return (
    <Container>
      <Button
        text="PLASEAZA COMANDA"
        onClick={() => {
          onHideCart();
          history.push("/plasare-comanda/detalii");
        }}
      />
      <p>Transportul si taxele aditionale se calculeaza la plasarea comenzii</p>
    </Container>
  );
};
