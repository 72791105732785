import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

import { Icon } from "../../Icon/Icon";
import { useAppState } from "../../../appState/appState";

const getIcon = (pathname, isUserLogged) => {
  if (pathname.includes("/auth/")) return "profile_selected";

  if (isUserLogged) return "profile_logged_in";

  return "profile";
};

const ProfileIcon = styled(Icon)`
  cursor: pointer;
`;

export const ProfileButton = () => {
  const { id } = useAppState().user;
  const { pathname } = useLocation();

  return (
    <Link to="/auth">
      <ProfileIcon icon={getIcon(pathname, !!id)} />
    </Link>
  );
};
