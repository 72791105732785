import React from "react";
import styled from "styled-components";
import { useAppState } from "../../appState/appState";
import { colors } from "../../styles/colors";
import { round } from "lodash";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const SubTitle = styled.h3``;

const ProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProductItem = styled.div`
  display: flex;
  background: ${colors.lightestGrey};
  margin: 10px 0px;
`;

const ProductName = styled.p`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0px 20px;
`;

const ProductCount = styled.p`
  width: 50px;
  border-left: 1px solid ${colors.grey};
  text-align: center;
  padding: 0px 20px;
`;

const ProductPrice = styled.p`
  width: 100px;
  border-left: 1px solid ${colors.grey};
  text-align: left;
  padding: 0px 20px;
`;

const Separator = styled.div`
  height: 1px;
  background: ${colors.grey};
  margin: 40px 0px;
`;

const DeliveryType = styled.p`
  margin: 0;
`;

const Total = styled.h3`
  text-align: right;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 40px;
`;

export const OrderSummary = ({ orderDetails, products, total }) => {
  return (
    <Container>
      <SubTitle>LISTA PRODUSE</SubTitle>
      <ProductsContainer>
        {products.map((product, index) => {
          const finalPrice = round(
            !!product.discount
              ? product.price - product.price * (product.discount / 100)
              : product.price,
            2
          );

          return (
            <ProductItem key={index}>
              <ProductName>Nume: {product.name}</ProductName>
              <ProductCount>Buc: {product.nrOfItems}</ProductCount>
              <ProductPrice>
                Pret: {product.nrOfItems * finalPrice}
              </ProductPrice>
            </ProductItem>
          );
        })}
      </ProductsContainer>
      <Separator />
      <SubTitle>LIVRARE PRIN</SubTitle>
      <DeliveryType>Curier ( 20 lei )</DeliveryType>
      <Separator />
      <SubTitle>LIVRARE LA</SubTitle>
      <DeliveryType>
        Adresa:
        {`Str. ${orderDetails.delivery.address}, ${orderDetails.delivery.city}`}
      </DeliveryType>
      <Separator />
      <Total>TOTAL: {round(total, 2)} Ron</Total>
    </Container>
  );
};
