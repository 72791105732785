import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Icon } from "./Icon/Icon";

export const AppLogo = styled(({ className, size }) => (
  <Link to="/" className={className}>
    <Icon icon="logo" size={size} className="app-logo" />
  </Link>
))`
  .app-logo {
    cursor: pointer;
  }
`;
