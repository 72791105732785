import React from "react";
import styled from "styled-components";
import { HederShortcuts } from "./HeaderShortcuts/HederShortcuts";
import { AppLogo } from "../AppLogo";
import { Menu } from "../Menu/Menu";
import { useLocation } from "react-router";

const HeaderContainer = styled.div.attrs({ className: "header-container" })`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 90px;
  box-sizing: border-box;
  position: relative;

  .app-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const useIsHomePage = () => {
  const { pathname } = useLocation();

  return pathname === "/";
};

export const Header = () => {
  const isHomePage = useIsHomePage();

  return (
    <HeaderContainer>
      <Menu />
      {!isHomePage && <AppLogo size={70} />}

      <HederShortcuts />
    </HeaderContainer>
  );
};
