import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { Icon } from "../Icon/Icon";

const Container = styled.div.attrs({
  className: "sort-dropdown__selected-item",
})`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-bottom: 5px;
  border-bottom: 2px solid
    ${({ expanded }) => (expanded ? colors.lightgrey : "transparent")};
`;

const Text = styled.p.attrs({
  className: "sort-dropdown__selected-item__text",
})`
  margin: 0;
`;

const ExpandIcon = styled(Icon).attrs({
  className: "sort-dropdown__selected-item__text",
})`
  margin-left: 5px;
  height: 18px;
  transform: ${({ expanded }) => (expanded ? "rotate(180deg)" : "unset")};
`;

const getText = (sortType, sortDirection) => {
  if (sortType && sortDirection) {
    switch (sortType) {
      case "price":
        return `SORTARE DUPA PRET - ${sortDirection.toUpperCase()}`;

      case "date":
        return `SORTARE DUPA DATA - ${sortDirection.toUpperCase()}`;

      default:
        return "SORTARE DUPA";
    }
  }

  return "SORTARE DUPA";
};

export const SelectedItem = ({
  onClick,
  expanded,
  sortType,
  sortDirection,
}) => {
  const text = getText(sortType, sortDirection);

  return (
    <Container onClick={onClick} expanded={expanded}>
      <Text>{text}</Text>
      <ExpandIcon icon="expand" expanded={expanded} />
    </Container>
  );
};
