import React from "react";
import styled from "styled-components";
import { actionDispatch } from "../../appState/actions";
import { useAppDispatch, useAppState } from "../../appState/appState";
import { colors } from "../../styles/colors";
import { Checkbox } from "../Inputs/Checkbox";
import { FilterSingleChoice } from "./common/FilterSingleChoice";

const Container = styled.div.attrs({
  className: "category-filter-container",
})`
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  border-radius: 20px;
  background: ${colors.blue};

  div.filter-box-container {
    margin-bottom: 0px;
    box-shadow: none;
    background: ${colors.green};
  }

  .filter-box-title {
    color: white;
  }

  .checkbox-label {
    color: white;
  }

  > .checkbox {
    margin: 10px 13px;

    .checkbox-label {
      font-weight: bold;
    }
  }
`;

export const FilterCategory = () => {
  const { category } = useAppState().productsFilters;
  const dispatch = useAppDispatch();

  return (
    <Container>
      <FilterSingleChoice
        title="CATEGORII"
        choiceList={category.filter(
          (categoryItem) => categoryItem.id !== "others"
        )}
        onClick={(id, value) =>
          dispatch(actionDispatch.productsFilters.setCategory(id, value))
        }
      />
      <Checkbox
        label="ALTELE"
        value={
          category.find((categoryItem) => categoryItem.id === "others").value
        }
        onChange={(value) =>
          dispatch(actionDispatch.productsFilters.setCategory("others", value))
        }
      />
    </Container>
  );
};
