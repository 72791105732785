import React, { Fragment } from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { Icon } from "../Icon/Icon";

const ItemContent = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  margin: 10px 0px;

  .icon:first-child {
    margin-left: auto;
  }
`;

const ItemText = styled.p`
  width: 100%;
  margin: 0;
  margin-right: auto;
  text-align: ${({ reset }) => (reset ? "center" : "left")};
  cursor: ${({ reset }) => (reset ? "pointer" : "default")};
  color: ${({ reset }) => (reset ? colors.green : "black")};
`;

const ItemIconASC = styled(Icon)`
  height: 20px;
  cursor: pointer;
  padding: 5px;
  border-radius: 100%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  margin-left: 5px;
`;

const ItemIconDESC = styled(ItemIconASC)`
  transform: rotate(180deg);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
`;

export const SortItem = ({ label, onClick, reset }) => {
  return (
    <ItemContent>
      <ItemText reset={reset} onClick={reset && onClick}>
        {label}
      </ItemText>
      {!reset && (
        <Fragment>
          <ItemIconASC
            icon="arrow"
            onClick={() => {
              onClick("asc");
            }}
          />
          <ItemIconDESC
            icon="arrow"
            onClick={() => {
              onClick("dsc");
            }}
          />
        </Fragment>
      )}
    </ItemContent>
  );
};
