import React from "react";
import styled from "styled-components";
import { useAppState } from "../../appState/appState";
import { colors } from "../../styles/colors";
import { useClickOutside } from "../../utils";
import { MenuItem } from "./MenuItem";

const Container = styled.div.attrs({ className: "menu-navigation" })`
  position: fixed;
  background: ${colors.green};
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 150px 98px;
  z-index: 1;
  transition: transform 0.3s ease;
  transform: translateX(${({ opened }) => (opened ? "0%" : "-100%")});
`;

export const MenuNavigation = ({ opened, onClose }) => {
  const ref = useClickOutside(opened ? onClose : undefined);
  const { isAdmin } = useAppState().user;

  return (
    <Container opened={opened} ref={ref}>
      <MenuItem path="/" label="ACASA" />
      <MenuItem path="/produse" label="PRODUSE" />
      {isAdmin && (
        <MenuItem path="/admin/adauga_produse" label="ADAUGA PRODUSE" />
      )}
      <MenuItem path="/contact" label="CONTACT" />
    </Container>
  );
};
