import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  padding-top: 100px;
  background: ${colors.lightgrey};
  position: relative;

  :before {
    content: "";
    position: absolute;
    top: -116px;
    left: 0;
    width: 100%;
    height: 116px;
    background: ${colors.lightgrey};
    border-top-left-radius: 100px;
    z-index: -1;
  }
`;

const SpecTitle = styled.h2`
  font-size: 30px;
  margin: 0;
  margin-bottom: 60px;
  padding-left: 160px;
  padding-right: 160px;
`;

const SpecSections = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SpecSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 50%;
  margin-bottom: 50px;

  .product__spec {
    padding-left: ${({ paddingLeftNone }) => (paddingLeftNone ? 0 : 160)}px;
    padding-right: ${({ paddingRightNone }) => (paddingRightNone ? 0 : 160)}px;
  }

  .product__spec_title {
    padding-left: ${({ paddingLeftNone }) => (paddingLeftNone ? 0 : 160)}px;
    padding-right: ${({ paddingRightNone }) => (paddingRightNone ? 0 : 160)}px;
  }
`;

const SpecSectionTitle = styled.h3.attrs({ className: "product__spec_title" })`
  margin: 0;
  font-size: 20px;
  margin-bottom: 40px;
`;

const getSpecBackground = (index) =>
  index % 2 === 0 ? colors.lightestGrey : "unset";

const Spec = styled.div.attrs({ className: "product__spec" })`
  display: flex;
  background: ${({ index }) => getSpecBackground(index)};

  box-sizing: border-box;
`;

const SpecName = styled.p`
  margin: 0;
  padding: 10px 0px;
  flex: 1;
  border-right: 1px solid ${colors.grey};
`;

const SpecValue = styled.p`
  margin: 0;
  padding: 10px 0px;
  padding-left: 20px;
  flex: 2;
`;

const isPaddingLeftNone = (index) => index % 2 === 1;
const isPaddingRightNone = (index) => index % 2 === 0;

const EmptySpec = () => (
  <Spec>
    <SpecName />
    <SpecValue />
  </Spec>
);

export const ProductSpecifications = ({ specs }) => {
  return specs ? (
    <Container>
      <SpecTitle>Specificatii</SpecTitle>
      <SpecSections>
        {specs.map(({ id, name, specList }, index) => {
          const paddingLeftNone = isPaddingLeftNone(index);
          const paddingRightNone = isPaddingRightNone(index);

          return (
            <SpecSection
              key={id}
              paddingLeftNone={paddingLeftNone}
              paddingRightNone={paddingRightNone}
            >
              <SpecSectionTitle>{name}</SpecSectionTitle>
              <EmptySpec />
              {specList &&
                specList.map(({ id, name, value }, index) => (
                  <Spec key={id} index={index}>
                    <SpecName>{name}</SpecName>
                    <SpecValue>{value}</SpecValue>
                  </Spec>
                ))}
              <EmptySpec />
            </SpecSection>
          );
        })}
      </SpecSections>
    </Container>
  ) : null;
};
