import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import { wiggleWiggleAnimation } from "../../styles/animations";
import { colors } from "../../styles/colors";

const TextFieldContainer = styled.div.attrs({
  className: "text-area-container",
})`
  box-sizing: border-box;
  width: 100%;
  border: 1px solid
    ${({ invalid, color }) => (invalid ? colors.red : color || "black")};
  display: flex;
  flex-direction: column;
  height: 200px;
  border-radius: 20px;
  font-size: 20px;
  padding: 0px 10px;
  overflow: hidden;
  transition: border-color 0.3s ease;

  ${({ invalid }) => (invalid ? wiggleWiggleAnimation : "")};
`;

const TextFieldArea = styled.textarea.attrs({ className: "text-field-area" })`
  height: 100%;
  background: none;
  border: none;
  outline: none;
  font-size: 20px;
  flex: 1;
  color: ${({ invalid, color }) => (invalid ? colors.red : color || "black")};
  padding: 0;
  font-size: 16px;
`;

const TextFieldLabel = styled.p.attrs({ className: "text-field-label" })`
  color: ${({ invalid, color }) => (invalid ? colors.red : color || "black")};
  margin: 10px 0xp;
  font-size: 16px;
`;

const handleOnEnter =
  (onEnter) =>
  ({ key, target: { value } }) => {
    if (key === "Enter") {
      onEnter(value);
    }
  };

export const TextArea = ({
  label,
  onChange,
  type,
  onBlur,
  value,
  invalid,
  onEnter,
  color,
}) => {
  const ref = useRef();

  const focus = useCallback(() => {
    ref.current.focus();
  }, []);

  return (
    <TextFieldContainer invalid={invalid} color={color} onClick={focus}>
      <TextFieldLabel invalid={invalid} color={color}>
        {label}:
      </TextFieldLabel>
      <TextFieldArea
        ref={ref}
        onChange={(event) => onChange(event.target.value)}
        type={type}
        onBlur={onBlur}
        value={value}
        invalid={invalid}
        onKeyDown={handleOnEnter(onEnter)}
        color={color}
      />
    </TextFieldContainer>
  );
};
