import React from "react";
import styled from "styled-components";
import { Icon } from "../components/Icon/Icon";
import { useIsUserLogged } from "../utils";

const Container = styled.div.attrs({ className: "favorite-button" })`
  display: flex;
  cursor: pointer;

  img {
    cursor: pointer;
  }
`;

export const FavoriteButton = ({ selected, onClick }) => {
  const isUserLogged = useIsUserLogged();

  return isUserLogged ? (
    <Container onClick={onClick}>
      <Icon icon={selected ? "favorite-red" : "favorite-grey"} />
    </Container>
  ) : null;
};
