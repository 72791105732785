import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAppState } from "../appState/appState";
import { colors } from "../styles/colors";
import { Icon } from "./Icon/Icon";

const NotificationContainer = styled.div.attrs({
  className: "notifications",
})`
  position: fixed;
  right: 40px;
  bottom: 40px;
  display: flex;
  align-items: center;
  background: white;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border-radius: 27px;
  padding-right: 10px;
  transform: translateY(${({ visible }) => (visible ? "0px" : "100px")});
  transition: transform 0.3s ease;

  .icon {
    cursor: pointer;
  }
`;

const IconContainer = styled.div.attrs({
  className: "notifications-icon-container",
})`
  background: ${colors.red};
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WarningIcon = () => <Icon icon="warning" size={50} />;

const CloseButton = ({ onClick }) => (
  <Icon icon="close" onClick={onClick} size={30} />
);

const TextContainer = styled.div.attrs({
  className: "notifications-text-container",
})`
  display: flex;
  align-items: center;
  padding: 0px 30px;
`;

const Text = styled.p.attrs({
  className: "notifications-text",
})`
  margin: 0;
`;

export const Notifications = () => {
  const [visible, setVisibility] = useState(false);
  const { message, messageId } = useAppState().notifications;

  useEffect(() => {
    if (!visible && message) {
      setVisibility(true);
    }
    // eslint-disable-next-line
  }, [messageId]);

  return (
    <NotificationContainer visible={visible}>
      <IconContainer>
        <WarningIcon />
      </IconContainer>
      <TextContainer>
        <Text>{message}</Text>
      </TextContainer>
      <CloseButton onClick={() => setVisibility(false)} />
    </NotificationContainer>
  );
};
