import React from "react";
import styled from "styled-components";
import { actionDispatch } from "../../appState/actions";
import { useAppDispatch } from "../../appState/appState";
import { Icon } from "../Icon/Icon";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.7);
  padding: 0px 10px;
  box-sizing: border-box;
`;

const SetCoverButton = styled(Icon)`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

const RemoveImgButton = styled(Icon)`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

const useHandleSetCoverImg = (id) => {
  const dispatch = useAppDispatch();

  return () => dispatch(actionDispatch.editProduct.setCoverImg(id));
};

const useHandleRemoveImg = (id) => {
  const dispatch = useAppDispatch();

  return () => {
    dispatch(actionDispatch.editProduct.removeImg(id));
  };
};

export const ImgsActions = ({ id, isCover }) => {
  const onSetCoverImg = useHandleSetCoverImg(id);
  const onRemoveImg = useHandleRemoveImg(id);

  return (
    <Container>
      <SetCoverButton
        icon={isCover ? "favorite-full" : "favorite"}
        onClick={onSetCoverImg}
      />
      <RemoveImgButton icon="trash" onClick={onRemoveImg} />
    </Container>
  );
};
