import React from "react";
import styled from "styled-components";
import { Icon } from "../../components/Icon/Icon";
import { colors } from "../../styles/colors";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Container = styled.div`
  height: 10px;
  background: ${colors.blue};
  width: 50%;
  border-radius: 5px;
  position: absolute;
  top: -54px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const Circle = styled.div`
  width: 54px;
  height: 54px;
  background: ${colors.blue};
  border-radius: 50%;
  transition: transform 0.3s ease;
  transform: scale(${({ selected }) => (selected ? "1.5" : "1")});
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InnerCircle = styled.div`
  width: 25px;
  height: 25px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .icon {
    height: 18px;
  }
`;

export const OrderStatus = () => {
  const { pathname } = useLocation();

  return (
    <Container>
      <Circle selected={pathname === "/plasare-comanda/detalii"}>
        <InnerCircle>
          <Icon icon="cart" />
        </InnerCircle>
      </Circle>
      <Circle selected={pathname === "/plasare-comanda/sumar"}>
        <InnerCircle>
          <Icon icon="summary" />
        </InnerCircle>
      </Circle>
      <Circle selected={pathname === "/plasare-comanda/finalizare"}>
        <InnerCircle>
          <Icon icon="check" />
        </InnerCircle>
      </Circle>
    </Container>
  );
};
