import styled from "styled-components";

const MARGIN_VALUE = "20px";

export const Card = styled.div.attrs({ className: "card" })`
  background: ${({ color }) => color || "white"};
  margin-right: ${({ marginRight }) => (marginRight ? MARGIN_VALUE : "0px")};
  margin-top: ${({ marginTop }) => (marginTop ? MARGIN_VALUE : "0px")};
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  padding: 40px;
  flex: 1;
  border-radius: 20px;
`;
