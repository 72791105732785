import React, { useRef } from "react";
import styled from "styled-components";
import { actionDispatch } from "../../appState/actions";
import { useAppDispatch } from "../../appState/appState";
import { colors } from "../../styles/colors";
import { resizeImg } from "../../utils";
import { Icon } from "../Icon/Icon";

const Container = styled.button`
  width: 60px;
  height: 60px;
  border-radius: 20px;
  box-shadow: 0px 3px 3px rgb(0, 0, 0, 0.1);

  overflow: hidden;
  flex-shrink: 0;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${colors.lightgrey};
`;

const UploadIcon = styled(Icon)``;

const Input = styled.input.attrs({ type: "file" })`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

const useHandleUploadImg = (inputRef) => {
  const dispatch = useAppDispatch();

  return async ({ target }) => {
    const [file] = target.files;
    if (file) {
      const image = await resizeImg(file);

      dispatch(actionDispatch.editProduct.addImg(image, file.type));

      if (inputRef.current) inputRef.current.value = "";
    }
  };
};

export const UploadImgButton = () => {
  const inputRef = useRef();
  const onUpload = useHandleUploadImg(inputRef);

  return (
    <Container>
      <UploadIcon icon="arrow" />
      <Input onChange={onUpload} ref={inputRef} />
    </Container>
  );
};
