export const actionsTypes = {
  loading: {
    show: "show-loading",
    hide: "hide-loading",
  },
  notifications: {
    setMessage: "set-message",
  },
  user: {
    setData: "set-data",
    clearData: "clear-data",
    addFavoriteProduct: "user-add-favorite-product",
    removeFavoriteProduct: "user-remove-favorite-product",
  },
  productsFilters: {
    setBrand: "set-filter-brand",
    setType: "set-filter-type",
    setCategory: "set-filter-category",
    setPrice: "set-price",
    setFavorite: "set-favorite",
    reset: "reset-filters",
  },

  productsSort: {
    reset: "reset-products-sort",
    setByPrice: "set-sort-by-price",
    setByDate: "set-sort-by-date",
  },
  products: {
    set: "set-products",
    add: "add-products",
    removeProduct: "remove-product",
  },
  lastProductItem: {
    set: "last-product-item-set",
    unset: "last-product-unset",
  },
  editProduct: {
    setField: "edit-product-set-field",
    addEmptySpecSection: "add-empty-spec-section",
    addEmptySpecItem: "add-empty-spec-item",
    editSpecSectionName: "edit-spec-section-name",
    editSpecName: "edit-spec-name",
    editSpecValue: "edit-spec-value",
    addImg: "edit-product__add-img",
    setCoverImg: "edit-product__set-cover-img",
    removeImg: "edit-product__remove-img",
    removeSection: "edit-product__remove-section",
    removeSectionItem: "edit-product__remove-section-item",
    setEditValues: "edit-product__set-edit-values",
    removeId: "edit-product__remove-id",
    clear: "edit-product__clear",
  },
  cart: {
    addItem: "cart-add-item",
    removeItem: "cart-remove-item",
    setVisibility: "cart-set-visibility",
    updateNrOfItems: "cart-update-number-of-items",
    reset: "cart-reset",
  },
};

export const actionDispatch = {
  loading: {
    setVisibility(value) {
      if (value)
        return {
          type: actionsTypes.loading.show,
        };

      return {
        type: actionsTypes.loading.hide,
      };
    },
  },

  notifications: {
    setMessage(message) {
      return {
        type: actionsTypes.notifications.setMessage,
        payload: message,
      };
    },
  },

  user: {
    setData(data) {
      return {
        type: actionsTypes.user.setData,
        payload: data,
      };
    },
    clearData() {
      return {
        type: actionsTypes.user.clearData,
      };
    },
    addFavoriteProduct(productId) {
      return {
        type: actionsTypes.user.addFavoriteProduct,
        payload: productId,
      };
    },
    removeFavoriteProduct(productId) {
      return {
        type: actionsTypes.user.removeFavoriteProduct,
        payload: productId,
      };
    },
  },

  productsFilters: {
    setBrand(id, value) {
      return {
        type: actionsTypes.productsFilters.setBrand,
        payload: {
          id,
          value,
        },
      };
    },
    setType(id, value) {
      return {
        type: actionsTypes.productsFilters.setType,
        payload: {
          id,
          value,
        },
      };
    },
    setCategory(id, value) {
      return {
        type: actionsTypes.productsFilters.setCategory,
        payload: {
          id,
          value,
        },
      };
    },
    setPrice(id, value) {
      return {
        type: actionsTypes.productsFilters.setPrice,
        payload: {
          id,
          value,
        },
      };
    },
    setFavorite(value) {
      return {
        type: actionsTypes.productsFilters.setFavorite,
        payload: value,
      };
    },
    reset() {
      return {
        type: actionsTypes.productsFilters.reset,
      };
    },
  },

  productsSort: {
    reset() {
      return {
        type: actionsTypes.productsSort.reset,
      };
    },
    setByPrice(direction) {
      return {
        type: actionsTypes.productsSort.setByPrice,
        payload: direction,
      };
    },
    setByDate(direction) {
      return {
        type: actionsTypes.productsSort.setByDate,
        payload: direction,
      };
    },
  },

  products: {
    set(payload) {
      return {
        type: actionsTypes.products.set,
        payload,
      };
    },

    add(payload) {
      return {
        type: actionsTypes.products.add,
        payload,
      };
    },
    removeProduct(productId) {
      return {
        type: actionsTypes.products.removeProduct,
        payload: productId,
      };
    },
  },

  lastProductItem: {
    set(payload) {
      return {
        type: actionsTypes.lastProductItem.set,
        payload,
      };
    },
    uset() {
      return {
        type: actionsTypes.lastProductItem.unset,
      };
    },
  },

  editProduct: {
    setField(fieldName, value) {
      return {
        type: actionsTypes.editProduct.setField,
        payload: {
          fieldName,
          value,
        },
      };
    },

    addEmptySpecSection() {
      return {
        type: actionsTypes.editProduct.addEmptySpecSection,
      };
    },

    addEmptySpecItem(specSectionName) {
      return {
        type: actionsTypes.editProduct.addEmptySpecItem,
        payload: specSectionName,
      };
    },
    editSpecSectionName(id, value) {
      return {
        type: actionsTypes.editProduct.editSpecSectionName,
        payload: {
          id,
          value,
        },
      };
    },
    editSpecName(sectionId, specId, value) {
      return {
        type: actionsTypes.editProduct.editSpecName,
        payload: {
          sectionId,
          specId,
          value,
        },
      };
    },
    editSpecValue(sectionId, specId, value) {
      return {
        type: actionsTypes.editProduct.editSpecValue,
        payload: {
          sectionId,
          specId,
          value,
        },
      };
    },
    removeSection(id) {
      return {
        type: actionsTypes.editProduct.removeSection,
        payload: id,
      };
    },
    removeSectionItem(sectionId, itemId) {
      return {
        type: actionsTypes.editProduct.removeSectionItem,
        payload: {
          sectionId,
          itemId,
        },
      };
    },
    addImg(data, type) {
      return {
        type: actionsTypes.editProduct.addImg,
        payload: {
          data,
          type,
        },
      };
    },
    removeImg(id) {
      return {
        type: actionsTypes.editProduct.removeImg,
        payload: id,
      };
    },
    setCoverImg(id) {
      return {
        type: actionsTypes.editProduct.setCoverImg,
        payload: id,
      };
    },
    setEditValues(values) {
      return {
        type: actionsTypes.editProduct.setEditValues,
        payload: values,
      };
    },
    removeId() {
      return {
        type: actionsTypes.editProduct.removeId,
      };
    },
    clear() {
      return {
        type: actionsTypes.editProduct.clear,
      };
    },
  },
  cart: {
    addItem(item) {
      return {
        type: actionsTypes.cart.addItem,
        payload: item,
      };
    },
    removeItem(id) {
      return {
        type: actionsTypes.cart.removeItem,
        payload: id,
      };
    },
    setVisibility(value) {
      return {
        type: actionsTypes.cart.setVisibility,
        payload: value,
      };
    },
    updateNumberOfItems(id, value) {
      return {
        type: actionsTypes.cart.updateNrOfItems,
        payload: {
          id,
          value,
        },
      };
    },
    rest() {
      return {
        type: actionsTypes.cart.reset,
      };
    },
  },
};
