import React from "react";
import styled, { keyframes } from "styled-components";
import { useAppState } from "../appState/appState";
import { colors } from "../styles/colors";

const lineAnimation = keyframes`
    to {
        stroke-dashoffset: 0;
        stroke-dasharray: 700;
    }
`;

const fadeInAnimation = keyframes`
    to {
        opacity: 1;
    }
`;

const Svg = styled.svg`
  path {
    stroke-width: 3px;
    stroke-dasharray: 400;
    stroke-dashoffset: 400;
    animation: ${lineAnimation} 2s ease infinite alternate;
    stroke: ${colors.blue};
  }
`;

const LoadingContainer = styled.div`
  z-index: 2;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  opacity: 0;
  animation: ${fadeInAnimation} 0.3s ease forwards;
`;

export const Loading = () => {
  const { loading } = useAppState();

  return loading ? (
    <LoadingContainer>
      <Svg
        width="100px"
        height="100px"
        viewBox="0 0 140 125"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M113.4 38.2C106.813 38.1988 100.481 40.7467 95.73 45.31V45.31C90.7492 49.9175 87.8013 56.3138 87.5339 63.0936C87.2666 69.8734 89.7016 76.482 94.304 81.4674C98.9065 86.4528 105.3 89.4072 112.079 89.6815C118.859 89.9557 125.47 87.5274 130.46 82.93V82.93C134.337 79.4755 137.072 74.9241 138.302 69.8795C139.533 64.8348 139.201 59.5353 137.351 54.6836C135.5 49.832 132.219 45.6574 127.941 42.7135C123.664 39.7697 118.593 38.1956 113.4 38.2V38.2ZM125.74 74.49C123.231 77.3762 119.788 79.2892 116.013 79.8947C112.237 80.5002 108.368 79.7597 105.083 77.8027C101.798 75.8456 99.3045 72.7964 98.039 69.1878C96.7735 65.5793 96.8162 61.6408 98.1597 58.0605C99.5031 54.4802 102.062 51.4858 105.389 49.6005C108.716 47.7151 112.599 47.0587 116.361 47.746C120.123 48.4333 123.524 50.4204 125.969 53.3604C128.415 56.3003 129.749 60.006 129.74 63.83C129.731 67.7487 128.311 71.533 125.74 74.49Z"
            stroke="#173B5A"
          />
          <path
            d="M91.71 43L111.21 1.07H66.02L70.84 10.39H96.6L83.31 38.98V38.99L71.67 64.01L56.41 31.95L41.2 0L25.97 33.09H25.96L0 89.46H39.71L36.01 80.14H14.56L36.9 31.62L41.34 21.98L45.78 31.3L66.57 74.98L43.31 124.99L53.66 124.84L91.72 43H91.71Z"
            stroke="#173B5A"
          />
        </g>
      </Svg>
    </LoadingContainer>
  ) : null;
};
