import { round } from "lodash";
import React from "react";
import styled from "styled-components";
import { actionDispatch } from "../../appState/actions";
import { useAppDispatch } from "../../appState/appState";
import { colors } from "../../styles/colors";
import { Icon } from "../Icon/Icon";
import { CartItemCount } from "./CartItemCount";

const Container = styled.div`
  display: flex;
  padding: 20px 20px;
  flex-shrink: 0;
  background: ${colors.lightestGrey};
  margin: 10px 0px;
  border-radius: 5px;
`;

const CoverImg = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
`;

const Text = styled.p`
  margin: 0;
  margin-top: 10px;
  margin-left: 20px;
  font-size: 12px;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100px;
  margin-left: auto;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;

  .actions-icon {
    height: 30px;
    cursor: pointer;
  }
`;

const Price = styled.p`
  font-size: 12px;
  font-weight: bold;
  text-align: right;
`;

const useOnRemoveItem = (id) => {
  const dispatch = useAppDispatch();

  return () => dispatch(actionDispatch.cart.removeItem(id));
};

export const CartItem = (props) => {
  const { id, imgs, name, price, nrOfItems, discount } = props;
  const finalPrice = round(
    !!discount ? price - price * (discount / 100) : price,
    2
  );
  const { src } = imgs.find(({ isCover }) => isCover) || {};
  const onRemoveItem = useOnRemoveItem(id);

  return (
    <Container>
      <CoverImg src={src} />
      <Text>{name}</Text>
      <ActionsContainer>
        <Actions>
          <CartItemCount id={id} nrOfItems={nrOfItems} />
          <Icon
            className="actions-icon"
            icon="trash"
            onClick={(event) => {
              event.stopPropagation();
              onRemoveItem();
            }}
          ></Icon>
        </Actions>
        <Price>{`${finalPrice * nrOfItems} Ron`}</Price>
      </ActionsContainer>
    </Container>
  );
};
