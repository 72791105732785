import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

import { Button } from "../components/Inputs/Button";

const showAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const hideAnimation = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const ModalOverlay = styled.div.attrs({ className: "modal-overlay" })`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  animation: ${({ modalVisibility }) =>
      modalVisibility ? showAnimation : hideAnimation}
    0.3s ease forwards;
`;

const ModalContainer = styled.div.attrs({ className: "modal-container" })`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  max-width: 900px;
  max-height: 500px;
  background: white;
  border-radius: 20px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const ModalContentContainer = styled.div.attrs({
  className: "modal-content-container",
})`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ButtonsContainer = styled.div.attrs({
  className: "modal-buttons-container",
})`
  display: flex;
  justify-items: flex-end;

  .button:first-child {
    margin-left: auto;
    margin-right: 20px;
  }
`;

const ModalTitle = styled.h2.attrs({ className: "modal-title" })`
  margin-bottom: 40px;
`;

export const Modal = ({
  children,
  visibility,
  onSecondaryButtonClick,
  onPrimaryButtonClick,
  primaryButtonDisabled,
  modalTitle,
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (visibility) {
      setShow(true);
    } else {
      setTimeout(() => {
        setShow(false);
      }, 300);
    }
  }, [visibility]);

  return show ? (
    <ModalOverlay modalVisibility={visibility}>
      <ModalContainer>
        {modalTitle && <ModalTitle>{modalTitle}</ModalTitle>}
        <ModalContentContainer>{children}</ModalContentContainer>
        <ButtonsContainer>
          <Button secondary text="Anuleaza" onClick={onSecondaryButtonClick} />
          <Button
            text="Salveaza"
            onClick={onPrimaryButtonClick}
            disabled={primaryButtonDisabled}
          />
        </ButtonsContainer>
      </ModalContainer>
    </ModalOverlay>
  ) : null;
};
