import React from "react";
import styled from "styled-components";
import { OrderCards } from "../../components/OrderProcess";
import { PageTitle } from "../../components/PageTitle";
import { colors } from "../../styles/colors";
import { ContactForm } from "./ContactForm";

const Container = styled.div.attrs({ className: "contact-page" })`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 50px;
`;

const Title = styled(PageTitle)`
  padding-left: 250px;
`;

const Content = styled.div`
  flex: 1;
  background: ${colors.lightgrey};
  margin-left: 100px;
  padding: 50px;
  border-top-left-radius: 100px;
  display: flex;
  justify-content: center;
`;

const InnerContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1080px;
`;

const Details = styled.div`
  flex: 1;
  display: flex;
  margin-bottom: 50px;
  gap: 40px;
`;

const Detail = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const DetailTitle = styled.h3``;

export const Contact = () => (
  <Container>
    <Title>Contact</Title>
    <Content>
      <InnerContent>
        <Details>
          <Detail>
            <DetailTitle>DATE DE CONTACT</DetailTitle>
            <p>Inregistrare comenzi, anulare si modificari comenzi</p>
            <ul>
              <li>PHONE: +40742389898</li>
              <li>MAIL: ayotek.service@gmail.com</li>
            </ul>
          </Detail>
          <Detail>
            <DetailTitle>RETUR</DetailTitle>
            <p>
              Cea mai rapida cale pentru a returna un produs este sa completezi
              si sa ne trimiti formularul de retur: AICI VA FI UN LINK
            </p>
          </Detail>
          <Detail>
            <DetailTitle>COMPANIE</DetailTitle>
            <ul>
              <li>SC Ayo Tek Service Trading SRL</li>
              <li>Adresa: Floresti, Urusagului, 19A, Cluj</li>
              <li>CUI: 43710729</li>
              <li>Registrul Comertului: J12/660/2021</li>
              <li>Telefon: +40742389898</li>
            </ul>
          </Detail>
        </Details>
        <OrderCards />
        <ContactForm />
      </InnerContent>
    </Content>
  </Container>
);
