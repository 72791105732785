import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { useAppState } from "../../appState/appState";
import { Button } from "../../components/Inputs/Button";
import { useGetSuggestedProductData } from "./HomeHooks";
import { SuggestedProductCard } from "./SuggestedProductCard";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 62px 20px;
  width: 100%;
`;

const SuggestedProductsTitle = styled.h3`
  margin: 0;
  font-size: 40px;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 54px 0px;
  gap: 20px;
  width: 100%;
  max-width: 1000px;
`;

const AllProductsButton = styled(Button)`
  align-self: center;
`;

const isFavoriteProduct = (favoriteProducts, id) => {
  if (favoriteProducts) {
    return favoriteProducts.includes(id);
  }

  return false;
};

export const SuggestedProducts = () => {
  const suggestedProducts = useGetSuggestedProductData();
  const history = useHistory();
  const { favorite: favoriteProducts } = useAppState().user;

  return (
    <Container>
      <SuggestedProductsTitle>Noile Produse</SuggestedProductsTitle>
      <CardContainer>
        {suggestedProducts.map((product) => {
          const favoriteProduct = isFavoriteProduct(
            favoriteProducts,
            product.id
          );

          return (
            <SuggestedProductCard
              key={product.id}
              {...product}
              favoriteProduct={favoriteProduct}
            />
          );
        })}
      </CardContainer>
      <AllProductsButton
        text="Toate Produsele"
        onClick={() => history.push("/produse")}
      />
    </Container>
  );
};
