import styled from "styled-components";
import { colors } from "../../styles/colors";

export const TextButton = styled.p.attrs({ className: "text-button" })`
  color: ${colors.green};
  cursor: pointer;
  margin: 0;

  &:hover {
    text-decoration: underline;
  }
`;
