import React from "react";
import styled from "styled-components";
import { actionDispatch } from "../../../appState/actions";
import { useAppDispatch, useAppState } from "../../../appState/appState";
import { AddButton } from "../../../components/AddButton";
import { Icon } from "../../../components/Icon/Icon";
import { EditField } from "../../../components/Inputs/EditField";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditSpecTitle = styled.h2`
  margin: 20px 0px;
  font-weight: normal;
`;

const AddSectionButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AddSectionButton = styled(AddButton)`
  width: 40px;
`;

const AddSectionButtonText = styled.p`
  margin: 0;
`;

const SpecSectionList = styled.div`
  display: flex;
  flex-direction: column;
`;

const SpecSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  div.edit-field {
    margin-bottom: 10px;
  }
`;

const SpecSectionNameContainer = styled.div`
  display: flex;
  align-items: center;

  .icon {
    margin-bottom: 10px;
  }
`;

const SpecFieldsListContainer = styled.div`
  display: flex;
  padding-left: 100px;
  flex-direction: column;
`;

const SpecFields = styled.div`
  display: flex;
  div.edit-field {
    margin-left: 10px;
  }
`;

const AddSpecItemPlus = styled.span`
  font-weight: bold;
  font-size: 20px;
  margin-right: 10px;
  margin-bottom: -1px;
`;

const AddSpecItem = styled.p`
  margin: 0;
  align-self: flex-end;
  margin: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const useAddEmptySection = () => {
  const dispatch = useAppDispatch();

  return () => dispatch(actionDispatch.editProduct.addEmptySpecSection());
};

const useAddEmptyItem = () => {
  const dispatch = useAppDispatch();

  return (id) => dispatch(actionDispatch.editProduct.addEmptySpecItem(id));
};

const useEditSectionName = () => {
  const dispatch = useAppDispatch();

  return (id, value) =>
    dispatch(actionDispatch.editProduct.editSpecSectionName(id, value));
};

const useEditSpecName = () => {
  const dispatch = useAppDispatch();

  return (sectionId, specId, value) =>
    dispatch(actionDispatch.editProduct.editSpecName(sectionId, specId, value));
};

const useEditSpecValue = () => {
  const dispatch = useAppDispatch();

  return (sectionId, specId, value) =>
    dispatch(
      actionDispatch.editProduct.editSpecValue(sectionId, specId, value)
    );
};

const useOnRemoveSection = () => {
  const dispatch = useAppDispatch();

  return (id) => dispatch(actionDispatch.editProduct.removeSection(id));
};

const useOnRemoveSectionItem = () => {
  const dispatch = useAppDispatch();

  return (sectionId, itemId) =>
    dispatch(actionDispatch.editProduct.removeSectionItem(sectionId, itemId));
};

export const EditSpecs = () => {
  const { specs } = useAppState().editProduct;
  const onAddEmptySection = useAddEmptySection();
  const onAddEmptyItem = useAddEmptyItem();
  const onSectionNameChange = useEditSectionName();
  const onSpecNameChange = useEditSpecName();
  const onSpecValueChange = useEditSpecValue();
  const onRemoveSection = useOnRemoveSection();
  const onRemoveSectionItem = useOnRemoveSectionItem();

  return (
    <Container>
      <EditSpecTitle>Specificatii</EditSpecTitle>
      <SpecSectionList>
        {specs.map(({ id, name, specList } = {}) => (
          <SpecSection key={id}>
            <SpecSectionNameContainer>
              <EditField
                value={name}
                onChange={(value) => onSectionNameChange(id, value)}
                placeholder="nume sectiune specificatii"
              />
              <Icon icon="trash" onClick={() => onRemoveSection(id)} />
            </SpecSectionNameContainer>

            <SpecFieldsListContainer>
              {specList.map(
                ({ id: specId, name: specName, value: specValue } = {}) => (
                  <SpecFields key={specId}>
                    <EditField
                      value={specName}
                      onChange={(value) => onSpecNameChange(id, specId, value)}
                      placeholder="nume specificatie"
                    />
                    <EditField
                      value={specValue}
                      onChange={(value) => onSpecValueChange(id, specId, value)}
                      placeholder="valoare specificatie"
                    />
                    <Icon
                      icon="trash"
                      onClick={() => onRemoveSectionItem(id, specId)}
                    />
                  </SpecFields>
                )
              )}
            </SpecFieldsListContainer>
            <AddSpecItem onClick={() => onAddEmptyItem(id)}>
              <AddSpecItemPlus>+</AddSpecItemPlus>
              adauga specificatie
            </AddSpecItem>
          </SpecSection>
        ))}
      </SpecSectionList>
      <AddSectionButtonContainer onClick={onAddEmptySection}>
        <AddSectionButton />
        <AddSectionButtonText>
          Adauga sectiune specificatii
        </AddSectionButtonText>
      </AddSectionButtonContainer>
    </Container>
  );
};
