import React from "react";
import styled from "styled-components";
import { Icon } from "../Icon/Icon";

const IconContainer = styled.div.attrs({
  className: "menu-icon-container",
})`
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;

  z-index: 2;
  .icon {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
  }

  .menu-icon {
    transition: transform 0.3s ease, opacity 0.2s ease;
    opacity: ${({ opened }) => (opened ? 0 : 1)};
    transform: translateX(${({ opened }) => (opened ? 100 : 0)}%);
  }

  .close-icon {
    transition: transform 0.3s ease, opacity 0.2s ease;
    opacity: ${({ opened }) => (opened ? 1 : 0)};
    transform: translateX(${({ opened }) => (opened ? 0 : -100)}%);
  }
`;

export const MenuIcon = ({ opened, onClick }) => {
  return (
    <IconContainer onClick={onClick} opened={opened}>
      <Icon icon="close-white" className="close-icon" />
      <Icon icon="menu" className="menu-icon" />
    </IconContainer>
  );
};
