import styled from "styled-components";

export const PageTitle = styled.h1.attrs({ className: "page-title" })`
  font-size: 80px;
  padding-left: 100px;
  font-weight: bold;
  padding-bottom: 50px;
  margin: 0;
  max-width: 50%;
`;
