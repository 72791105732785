import React from "react";
import styled from "styled-components";
import { wiggleWiggleAnimation } from "../../styles/animations";
import { colors } from "../../styles/colors";

const Container = styled.div.attrs({ className: "checkbox" })`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${({ invalid }) => (invalid ? wiggleWiggleAnimation : "")}
`;

const Check = styled.div.attrs({ className: "checkbox-check" })`
  width: 16px;
  height: 16px;
  background: white;
  border: 2px solid ${colors.grey};
  border-radius: 100%;
  position: relative;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
      scale(${({ checked }) => (checked ? 1 : 0)});
    background: ${colors.blue};
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 100%;

    transition: transform 0.1s ease;
  }
`;

const Label = styled.p.attrs({ className: "checkbox-label" })`
  margin: 0;
  margin-top: 3px;
  margin-left: 8px;
  cursor: pointer;
`;

export const Checkbox = ({ value = false, label, onChange, invalid }) => {
  return (
    <Container onClick={() => onChange(!value)} invalid={invalid}>
      <Check checked={value} />
      {label && <Label>{label}</Label>}
    </Container>
  );
};
