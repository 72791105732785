import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { useIsFavoriteProduct } from "../Product/ProductHooks";
import { ProductItem } from "../Products/ProductItem";
import { useGetPromoProductData } from "./HomeHooks";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 62px 320px;
`;

const PromoProductTitle = styled.h1`
  font-size: 60px;
  margin: 0;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid ${colors.lightgrey};
  text-align: center;
`;

export const PromoProduct = () => {
  const promoProduct = useGetPromoProductData();
  const { id, name, stockStatus, price, imgs, description, discount } =
    promoProduct;
  const isFavoriteProduct = useIsFavoriteProduct();

  return (
    <Container>
      <PromoProductTitle>Produsul Promotional</PromoProductTitle>
      <ProductItem
        id={id}
        name={name}
        stockStatus={stockStatus}
        price={price}
        favoriteProduct={isFavoriteProduct(id)}
        imgs={imgs || []}
        description={description}
        promoLayout
        discount={discount}
      />
    </Container>
  );
};
