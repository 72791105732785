import { useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";

export const ScrollToTop = withRouter(() => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      document.querySelector(".page-container").scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
});
