import { round } from "lodash";
import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { actionDispatch } from "../../appState/actions";
import { useAppDispatch } from "../../appState/appState";
import { useHandleRequestFavoriteProduct } from "../../appState/helpers";
import { FavoriteButton } from "../../components/FavoriteButton";
import { IconButton } from "../../components/Inputs/IconButton";
import { colors } from "../../styles/colors";

const Container = styled.div`
  background: white;
  width: 250px;
  height: 350px;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 4.03266px 4.03266px 16.1306px rgba(0, 0, 0, 0.1);
  position: relative;
  cursor: pointer;

  .favorite-button {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;

const ImgContainer = styled.div`
  flex: 1;
  overflow: hidden;
  padding: 40px 20px 0px 20px;
`;

const CardImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 10px 20px;
  align-items: center;
`;

const TextName = styled.h3`
  font-size: 18px;
  margin: 0;
  max-height: 20px;
  overflow: hidden;
  word-break: break-all;
`;

const TextStock = styled.p`
  font-size: 10px;
  color: ${colors.green};
  margin: 0;
  margin-bottom: auto;
`;

const TextPrice = styled.h3`
  font-size: 20px;
  color: ${colors.red};
  margin: 10px;
`;

const TextFullPrice = styled.p`
  font-size: 14px;
  margin: 0;
  text-decoration: line-through;
`;

const DiscountText = styled.p`
  font-size: 16px;
  background: #e43333;
  margin: 0px;
  padding: 5px 58px;
  color: white;
  position: absolute;
  top: 16px;
  left: -59px;
  transform: rotate(-45deg);
`;

const useAddToCart = (item) => {
  const dispatch = useAppDispatch();

  return () => dispatch(actionDispatch.cart.addItem(item));
};

export const SuggestedProductCard = (props) => {
  const { id, name, imgs, stockStatus, price, favoriteProduct, discount } =
    props;

  const finalPrice = round(
    !!discount ? price - price * (discount / 100) : price,
    2
  );

  const { src } = imgs.find(({ isCover }) => isCover) || {};
  const history = useHistory();
  const handleClickOnFavButton = useHandleRequestFavoriteProduct(
    id,
    favoriteProduct
  );

  const onAddToCart = useAddToCart(props);

  return (
    <Container onClick={() => history.push(`/produse/${id}`)}>
      <ImgContainer>
        <CardImg src={src} />
      </ImgContainer>
      <TextContainer>
        <FavoriteButton
          selected={favoriteProduct}
          onClick={(event) => {
            event.stopPropagation();
            handleClickOnFavButton();
          }}
        />
        <TextName>{name}</TextName>
        <TextStock>{stockStatus}</TextStock>

        {!!discount && <TextFullPrice>{price} RON</TextFullPrice>}

        <TextPrice>{finalPrice} RON</TextPrice>
        <IconButton
          text="Adauga in cos"
          icon="cart-white"
          onClick={(event) => {
            event.stopPropagation();
            onAddToCart();
          }}
        />
      </TextContainer>
      {discount ? <DiscountText>reducere</DiscountText> : null}
    </Container>
  );
};
