import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../styles/colors";

const Container = styled.div.attrs({ className: "menu-item" })`
  margin: 10px 0px;
  cursor: pointer;
`;

const Label = styled.h3.attrs({ className: "menu-item__text" })`
  margin: 0;
  transition: color 0.3s ease;
  color: ${({ isSelected }) => (isSelected ? colors.blue : "white")};
`;

const useIsSelected = (path) => {
  const { pathname } = useLocation();

  if (pathname === path) return true;

  return false;
};

export const MenuItem = ({ path, label }) => {
  const isSelected = useIsSelected(path);

  return (
    <Container>
      <Link to={path}>
        <Label isSelected={isSelected}>{label}</Label>
      </Link>
    </Container>
  );
};
