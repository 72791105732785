import { keyframes, css } from "styled-components";

const wiggleWiggle = keyframes`
  0% {
    transform: translateX(0px);
  }

  20% {
    transform: translateX(-10px);
  }

  40% {
    transform: translateX(10px);
  }

  60% {
    transform: translateX(-5px);
  }

  80% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX(0px);
  }
`;

export const wiggleWiggleAnimation = css`
  animation: ${wiggleWiggle} 0.5s ease forwards;
`;
