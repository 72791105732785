import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { colors } from "../../styles/colors";

const StyledButton = styled.button.attrs({ className: "button" })`
  min-width: 210px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: ${({ secondary }) => (secondary ? colors.green : "white")};
  background: ${({ secondary }) => (secondary ? "white" : colors.green)};
  border: ${({ secondary }) =>
    secondary ? `2px solid ${colors.green}` : "none"};
  border-radius: 50px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  padding-left: ${({ icon }) => (icon ? "30px" : "unset")};

  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const animation = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.2);
  }

  to {
    opacity: 0.6;
    transform: translate(-50%, -50%) scale(1);
  }
`;

const ClickHighlight = styled.div.attrs({
  className: "button-click-highlight",
})`
  width: 100%;
  padding-bottom: 100%;
  border: 20px solid ${({ secondary }) => (secondary ? colors.green : "white")};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  border-radius: 50%;

  animation: ${animation} 0.5s ease-in;
`;

const useButtonAnimation = () => {
  const [showClickAnimation, setShowClickAnimation] = useState(false);

  const timeoutRef = useRef();

  useEffect(() => {
    if (showClickAnimation) {
      timeoutRef.current = setTimeout(() => {
        setShowClickAnimation(false);
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }, 1000);
    } else if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
      setShowClickAnimation(true);
    }

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [showClickAnimation]);

  return [showClickAnimation, setShowClickAnimation];
};

export const Button = ({
  text,
  onClick = () => {},
  disabled,
  secondary,
  icon,
  className,
}) => {
  const [showClickAnimation, setShowClickAnimation] = useButtonAnimation();

  const handleClick = (event) => {
    onClick(event);
    setShowClickAnimation(!showClickAnimation);
  };

  return (
    <StyledButton
      secondary={secondary}
      onClick={disabled ? null : handleClick}
      disabled={disabled}
      className={className}
      icon={icon}
    >
      {icon}
      {text}
      {showClickAnimation && <ClickHighlight secondary={secondary} />}
    </StyledButton>
  );
};
