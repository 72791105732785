import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { actionDispatch } from "../../appState/actions";
import { useAppDispatch, useAppState } from "../../appState/appState";
import { getProduct } from "../../utils";

export const useGetProductData = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [product, setProduct] = useState({});

  useEffect(() => {
    dispatch(actionDispatch.loading.setVisibility(true));
    getProduct(id)
      .then((product) => {
        setProduct(product);
        dispatch(actionDispatch.loading.setVisibility(false));
      })
      .catch(() => {
        dispatch(actionDispatch.loading.setVisibility(false));
        dispatch(
          actionDispatch.notifications.setMessage("Produsul nu a fost gasit")
        );
      });
    // eslint-disable-next-line
  }, []);

  return product;
};

export const useIsFavoriteProduct = () => {
  const { favorite: favoriteProducts } = useAppState().user;

  return (id) => {
    if (favoriteProducts) {
      return favoriteProducts.includes(id);
    }

    return false;
  };
};
