import React from "react";
import styled, { keyframes } from "styled-components";

const showAnimation = keyframes`
  from {
   opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const EmailSendMessageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;

  animation: ${showAnimation} 1s ease forwards;
`;

const EmailSendMessageText = styled.h1`
  color: white;
`;

export const EmailSendMessage = ({ message }) => {
  return (
    <EmailSendMessageContainer>
      <EmailSendMessageText>{message}</EmailSendMessageText>
    </EmailSendMessageContainer>
  );
};
