import React from "react";
import styled, { css } from "styled-components";
import { PageTitle } from "../../components/PageTitle";
import { PageSubTitle } from "../../components/PageSubTitle";
import { colors } from "../../styles/colors";
import { AppLogo } from "../../components/AppLogo";
import { Button } from "../../components/Inputs/Button";
import { useHistory } from "react-router";

const spacing = css`
  margin-top: 50px;
  margin-left: 160px;
  margin-right: 160px;
`;

const BannerContainer = styled.div.attrs({ className: "products-banner" })`
  display: flex;
  position: relative;

  ${({ homeBanner }) => (homeBanner ? spacing : "")}

  width: ${({ homeBanner }) => (homeBanner ? "unset" : "100%")};

  .page-title {
    padding-left: 0;
    color: ${colors.blue};
  }

  .page-subtitle {
    color: ${colors.blue};
  }

  .product-banner__app-logo {
    margin-bottom: 20px;
  }
`;

const BannerTextContainer = styled.div.attrs({
  className: "products-banner__text-container",
})`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

const BannerImgContainer = styled.div.attrs({
  className: "products-banner__img-container",
})`
  position: relative;
  flex: 1;
  height: ${({ homeBanner }) => (homeBanner ? 600 : 400)}px;
  overflow: hidden;
  border-top-right-radius: 100px;
`;

const BannerImg = styled.img.attrs(({ homeBanner }) => ({
  className: "products-banner__img",
  src: homeBanner ? "/img/banner.png" : "/img/banner-products.jpg",
}))`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`;

const PromotionContainer = styled.div.attrs({
  className: "products-banner__promotion-container",
})`
  position: relative;
  width: calc(50% + 100px);
  height: 70px;
  display: flex;
  align-items: center;
  background: linear-gradient(270deg, #008e95 0%, #173b5a 98.4%);
  position: absolute;
  left: 0;
  bottom: 40px;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 700px;
    background: ${colors.lightgrey};
    z-index: -1;
    border-bottom-left-radius: 100px;
  }
`;

const PromotionText = styled.div.attrs({
  className: "products-banner__promotion-text",
})`
  flex: 1;
  flex-direction: column;

  p {
    color: white;
    margin: 0;
    margin-left: 60px;

    &:first-child {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
`;

const PromotionValue = styled.p.attrs({
  className: "products-banner__promotion-value",
})`
  margin: 0;
  color: white;
  margin-right: 20px;
  font-size: 30px;
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: 50px;
`;

export const ProductsBanner = ({ homeBanner, pageTitle }) => {
  const history = useHistory();

  return (
    <BannerContainer homeBanner={homeBanner}>
      <BannerTextContainer>
        {homeBanner && (
          <AppLogo size={150} className="product-banner__app-logo" />
        )}
        <PageTitle>{pageTitle || "Produse"}</PageTitle>
        {!homeBanner && (
          <PageSubTitle>
            Toate produsele indeplinesc conditiile de conformitate si
            beneficiaza de garantie.
          </PageSubTitle>
        )}
        {homeBanner && (
          <ActionsContainer>
            <Button
              text="Descopera Produsele"
              onClick={() => history.push("/produse")}
            />
            <Button
              text="Contacteaza-ne"
              onClick={() => history.push("/contact")}
            />
          </ActionsContainer>
        )}
      </BannerTextContainer>
      <BannerImgContainer homeBanner={homeBanner}>
        <BannerImg homeBanner={homeBanner} />
      </BannerImgContainer>
      <PromotionContainer>
        <PromotionText>
          <p>Promotia Saptamanii: </p>
          <p>Urmareste produsele cu eticheta rosie!</p>
        </PromotionText>
        <PromotionValue>50%</PromotionValue>
      </PromotionContainer>
    </BannerContainer>
  );
};
