import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-image: url("/img/cart-banner.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const LogoWhite = styled.img.attrs({ src: "/img/ayo-white.png" })`
  height: 60%;
  margin-right: 50px;
`;

export const CartBanner = () => {
  return (
    <Container>
      <LogoWhite />
    </Container>
  );
};
