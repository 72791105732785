import React from "react";
import styled from "styled-components";
import { CartItems } from "../../components/Cart/CartItems";
import { CartTotalPrice } from "../../components/Cart/CartTotalPrice";
import { TextField } from "../../components/Inputs/TextField";
import { colors } from "../../styles/colors";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubTitle = styled.h3``;

const DeliveryType = styled.p`
  margin: 0;
`;

const DeliveryTypeNote = styled.p`
  margin: 0;
  color: ${colors.grey};
  padding-bottom: 10px;
  border-bottom: 1px solid ${colors.grey};
  font-size: 14px;
`;

const AddressContainer = styled.div`
  flex: 1;
  display: flex;
  gap: 20px;
  padding-bottom: 50px;
  border-bottom: 1px solid ${colors.grey};

  .text-field-container {
    margin-top: 10px;
    .text-field-label {
      color: ${colors.green};
    }
  }
`;

const Delivery = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px;
  border-radius: 8px;
`;

const Invoice = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px;
  border-radius: 8px;
`;

export const OrderDetails = ({
  setDeliveryField,
  orderDetails,
  setInvoiceField,
}) => {
  return (
    <Container>
      <SubTitle>LIVRARE PRIN</SubTitle>
      <DeliveryType>Curier</DeliveryType>
      <DeliveryTypeNote>
        * Pretul de calculeaza in functie de distanta
      </DeliveryTypeNote>
      <SubTitle>ADRESE</SubTitle>
      <AddressContainer>
        <Delivery>
          <DeliveryType>Livrare:</DeliveryType>
          <TextField
            label="Strada si numbar"
            value={orderDetails.delivery.address || ""}
            onChange={(value) => {
              setDeliveryField("address", value);
            }}
          />
          <TextField
            label="Oras"
            value={orderDetails.delivery.city || ""}
            onChange={(value) => {
              setDeliveryField("city", value);
            }}
          />
          <TextField
            label="Email"
            value={orderDetails.delivery.email || ""}
            onChange={(value) => {
              setDeliveryField("email", value);
            }}
          />
          <TextField
            label="Mobil"
            value={orderDetails.delivery.phone || ""}
            onChange={(value) => {
              setDeliveryField("phone", value);
            }}
          />
        </Delivery>
        <Invoice>
          <DeliveryType>Facturare:</DeliveryType>
          <TextField
            label="Strada si numbar"
            value={orderDetails.invoice.address || ""}
            onChange={(value) => {
              setInvoiceField("address", value);
            }}
          />
          <TextField
            label="Oras"
            value={orderDetails.invoice.city || ""}
            onChange={(value) => {
              setInvoiceField("city", value);
            }}
          />
          <TextField
            label="Nume Firma"
            value={orderDetails.invoice.company || ""}
            onChange={(value) => {
              setInvoiceField("company", value);
            }}
          />
          <TextField
            label="CUI"
            value={orderDetails.invoice.vat || ""}
            onChange={(value) => {
              setInvoiceField("vat", value);
            }}
          />
        </Invoice>
      </AddressContainer>
      <SubTitle>PRODUSE</SubTitle>
      <CartItems stretch />
      <CartTotalPrice left />
    </Container>
  );
};
