import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Icon } from "../Icon/Icon";
import { Flyout } from "./Flyout";

const Container = styled.div.attrs({ className: "dropdown" })`
  flex: 1;
  cursor: pointer;
`;

const ComponentContainer = styled.div.attrs({ className: "dropdown" })`
  width: 100%;
  position: relative;
  height: 40px;
`;

const ExpandButton = styled(Icon)`
  position: absolute;
  bottom: 6px;
  right: 10px;
  height: 25px;
  z-index: ${({ opened }) => (opened ? "3" : "1")};
  transform: rotate(${({ opened }) => (opened ? "180deg" : "0")});
`;

const Label = styled.p.attrs({ className: "dropdown__label" })`
  margin: 0;
  margin-bottom: 10px;
  font-size: 20px;
`;

export const Dropdown = ({
  label,
  selectedValue,
  options,
  onSelectItem,
  addField,
}) => {
  const [opened, setOpened] = useState(false);
  const onClickOutSide = useCallback(() => {
    setOpened(false);
  }, []);

  return (
    <Container>
      <Label>{label}</Label>
      <ComponentContainer onClick={opened ? undefined : () => setOpened(true)}>
        <ExpandButton icon="expand" opened={opened} />
        <Flyout
          selectedValue={selectedValue}
          opened={opened}
          options={options}
          onSelectedValueClick={() => setOpened(false)}
          onClickOutSide={opened ? onClickOutSide : undefined}
          onSelectItem={onSelectItem}
          addField={addField}
        />
      </ComponentContainer>
    </Container>
  );
};
