import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { MenuIcon } from "./MenuIcon";
import { MenuNavigation } from "./MenuNavigation";

const Container = styled.div.attrs({
  className: "menu-container",
})``;

export const Menu = () => {
  const [opened, setOpened] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setOpened(false);
  }, [pathname]);

  return (
    <Container>
      <MenuIcon opened={opened} onClick={() => setOpened(!opened)} />
      <MenuNavigation
        opened={opened}
        onClose={() => {
          if (opened) setOpened(false);
        }}
      />
    </Container>
  );
};
