import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useAppState } from "../../appState/appState";
import { AddButton } from "../../components/AddButton";

const AddButtonStyled = styled(AddButton).attrs({
  className: "add-products-button",
})`
  width: 60px;
  height: 60px;
  margin-top: -10px;
  margin-bottom: -10px;
  cursor: pointer;
`;

export const AddProductsButton = () => {
  const { isAdmin } = useAppState().user;
  const history = useHistory();

  return isAdmin ? (
    <AddButtonStyled
      onClick={() => {
        history.push("/admin/adauga_produse");
      }}
    />
  ) : null;
};
