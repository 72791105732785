import React from "react";
import styled from "styled-components";
// eslint-disable-next-line
import { Filters } from "../../components/Filters/Filters";
import { ProductsList } from "./ProductsList";

const Container = styled.div.attrs({ className: "products-body" })`
  padding: 80px 20px;
  display: flex;
  max-width: 1500px;
`;

export const ProductsBody = ({ loading }) => {
  return (
    <Container>
      {/* <Filters /> */}
      <ProductsList loading={loading} />
    </Container>
  );
};
