import React, { useEffect } from "react";
import styled from "styled-components";
import { PageTitle } from "../../../components/PageTitle";
import { getOrdersByUser } from "../../../utils";
import { Page } from "../../Page";

const MyOrdersContainer = styled(Page).attrs({ className: "profile" })``;

const Title = styled(PageTitle).attrs({ children: "Comenzile mele" })`
  display: flex;
  max-width: unset;
  align-items: flex-end;
  padding-left: unset;

  .icon {
    margin-left: auto;
    margin-right: -10px;
    cursor: pointer;
  }
`;

const OrderList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MyOrders = () => {
  useEffect(() => {
    getOrdersByUser();
  }, []);

  return (
    <MyOrdersContainer>
      <Title />
      <OrderList />
    </MyOrdersContainer>
  );
};
