import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { Icon } from "../Icon/Icon";
import { Button } from "./Button";

const IconContainer = styled.div.attrs({
  className: "icon-button__icon-container",
})`
  width: 40px;
  height: 40px;
  background: ${colors.blue};
  border-radius: 100%;
  align-self: flex-start;
  /* transform: translate(-14px, -1px); */
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  * {
    cursor: pointer;
  }
`;

const IconButtonIcon = styled(Icon).attrs({ className: "icon-button__icon" })`
  height: 28px;
`;

const IconButtonIconAssemble = ({ icon }) => (
  <IconContainer>
    <IconButtonIcon icon={icon} />
  </IconContainer>
);

export const IconButton = ({
  text,
  onClick,
  disabled,
  secondary,
  icon,
  className,
}) => (
  <Button
    text={text}
    onClick={onClick}
    disabled={disabled}
    secondary={secondary}
    className={className}
    icon={<IconButtonIconAssemble icon={icon} />}
  />
);
