import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { ProductMainInfoFooter } from "./ProductMainInfoFooter";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
`;

const ProductName = styled.h3`
  margin: 16px 0px 8px 0px;
  font-size: 24px;
`;

const ProductStockStatus = styled.p`
  margin: 0;
  color: ${colors.green};
`;

const ProductDescriptionLabel = styled.h3`
  margin: 54px 0px 6px 0px;
  font-size: 24px;
`;

const ProductDescription = styled.p`
  margin: 0;
  min-height: 97px;
`;

export const ProductMainInfo = (props) => {
  const { name, stockStatus, description } = props;

  return (
    <Container>
      <ProductName>{name}</ProductName>
      <ProductStockStatus>{stockStatus}</ProductStockStatus>
      <ProductDescriptionLabel>Descriere</ProductDescriptionLabel>
      <ProductDescription>{description}</ProductDescription>
      <ProductMainInfoFooter {...props} />
    </Container>
  );
};
