import styled from "styled-components";

export const Page = styled.div.attrs({ className: "page" })`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  padding-left: 160px;
  padding-right: 160px;
`;
