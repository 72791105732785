import React from "react";
import styled, { keyframes, css } from "styled-components";
import { colors } from "../styles/colors";

const loadingKeyFrames = keyframes`
 0% { transform: rotate(0deg); border-color: ${colors.grey}; }
 25% { border-color: ${colors.blue}; }
 50% { transform: rotate(119deg); border-color: ${colors.grey};  }
 100% { transform: rotate(119deg); border-color: ${colors.grey};  }
`;

const animation = (delay) => css`
  animation: ${loadingKeyFrames} 1s ${delay}s infinite;
`;

const SmallLoadingContainer = styled.div`
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const ArrowUp = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent !important;
  border-right: 10px solid transparent !important;
  border-bottom: 17.3333px solid ${colors.grey};
  transform-origin: 50% 66.66%;

  ${({ delay }) => animation(delay || 0)}
`;

export const SmallLoading = ({ className }) => {
  return (
    <SmallLoadingContainer className={className}>
      <ArrowUp />
      <ArrowUp delay={0.25} />
      <ArrowUp delay={0.5} />
    </SmallLoadingContainer>
  );
};
