import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import styled from "styled-components";

import { AppStateProvider } from "./appState/appState";
import { Header } from "./components/Header/Header";
import { Auth } from "./pages/Auth/Auth";
import { Home } from "./pages/Home/Home";
import { Products } from "./pages/Products/Products";
import { EditProducts } from "./pages/Admin/EditProducts/EditProducts";
import { Loading } from "./components/Loading";
import { Footer } from "./components/Footer/Footer";
import { Notifications } from "./components/Notifications";
import { useUpdateUserData } from "./appState/helpers";
import { AddProducts } from "./pages/Admin/AddProducts";
import { Product } from "./pages/Product/Product";
import { ScrollToTop } from "./components/ScroolToTop";
import { Contact } from "./pages/Contact/Contact";
import { Cart } from "./components/Cart/Cart";
import { PlaceOrder } from "./pages/PlaceOrder/PlaceOrder";
import { OrderSent } from "./pages/PlaceOrder/OrderSent";
import { MyOrders } from "./pages/Auth/Orders/Orders";

const PageContainer = styled.div.attrs({ className: "page-container" })`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;

const AppContainer = styled.div.attrs({ className: "app-container" })`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const AppInitializer = (props) => {
  useUpdateUserData();

  return <AppContainer {...props} />;
};

export const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <AppStateProvider>
        <AppInitializer>
          <Header />
          <PageContainer>
            <Switch>
              <Route path="/auth/profile/orders">
                <MyOrders />
              </Route>
              <Route path="/auth">
                <Auth />
              </Route>
              <Route path="/produse/:id">
                <Product />
              </Route>
              <Route path="/produse">
                <Products />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
              <Route path="/plasare-comanda/finalizata">
                <OrderSent />
              </Route>
              <Route path="/plasare-comanda">
                <PlaceOrder />
              </Route>
              <Route path="/admin/editeaza_produse/:id">
                <EditProducts />
              </Route>
              <Route path="/admin/adauga_produse">
                <AddProducts />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
            <Loading />
            <Footer />
          </PageContainer>
          <Cart />
          <Notifications />
        </AppInitializer>
      </AppStateProvider>
    </Router>
  );
};
