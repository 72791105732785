import { useEffect, useState } from "react";
import {
  addFavoriteProductToUser,
  getProducts,
  getUserDetails,
  removeFavoriteProductFromUser,
} from "../utils";
import { actionDispatch } from "./actions";
import { useAppDispatch, useAppState } from "./appState";
import { isEmpty } from "lodash";

export const useUpdateUserData = () => {
  const token = localStorage.getItem("token");
  const dispatch = useAppDispatch();

  if (token) {
    getUserDetails()
      .then((user) => dispatch(actionDispatch.user.setData(user)))
      .catch((err) => {
        if (err?.response.status === 401) {
          console.log("expired token");
          localStorage.removeItem("token");
          dispatch(actionDispatch.user.setData({}));
        }
      });
  }
};

export const useRequestProductsEffect = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getProducts().then((response) => {
      if (response) {
        if (response.list) dispatch(actionDispatch.products.set(response.list));
        if (response.lastItem)
          dispatch(actionDispatch.lastProductItem.set(response.lastItem));
      }
      setLoading(false);
    });
  }, [dispatch]);

  return loading;
};

const pickProductFilters = (productsFilters) => {
  if (productsFilters) {
    const filters = Object.keys(productsFilters).reduce((acc, filterType) => {
      if (filterType === "favorite" && productsFilters[filterType])
        return (acc[filterType] = true) && acc;

      return acc;
    }, {});

    return isEmpty(filters) ? null : filters;
  }

  return null;
};

export const handleRequestProducts = (state, dispatch) => () => {
  const { productsSort, lastProductItem, productsFilters } = state;
  const filters = pickProductFilters(productsFilters);

  if (lastProductItem && !productsFilters.favorite) {
    getProducts(lastProductItem, productsSort, filters).then((response) => {
      if (response) {
        if (response.list) dispatch(actionDispatch.products.add(response.list));

        if (response.lastItem) {
          dispatch(actionDispatch.lastProductItem.set(response.lastItem));
        } else {
          dispatch(actionDispatch.lastProductItem.uset());
        }
      }
    });

    return;
  }

  getProducts(null, productsSort, filters).then((response) => {
    if (response) {
      if (response.list) dispatch(actionDispatch.products.set(response.list));

      if (response.lastItem) {
        dispatch(actionDispatch.lastProductItem.set(response.lastItem));
      } else {
        dispatch(actionDispatch.lastProductItem.uset());
      }
    }
  });
};

export const useHandleRequestFavoriteProduct = (productId, favoriteProduct) => {
  const dispatch = useAppDispatch();
  const { favorite } = useAppState().productsFilters;

  return () => {
    if (favoriteProduct) {
      removeFavoriteProductFromUser(productId).then(() => {
        dispatch(actionDispatch.user.removeFavoriteProduct(productId));
        if (favorite) {
          dispatch(actionDispatch.products.removeProduct(productId));
        }
      });
    } else {
      addFavoriteProductToUser(productId).then(() =>
        dispatch(actionDispatch.user.addFavoriteProduct(productId))
      );
    }
  };
};
