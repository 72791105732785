import React from "react";
import styled from "styled-components";
import { Checkbox } from "./Checkbox";

const Container = styled.div.attrs({ className: "checkbox-array" })`
  display: flex;
  align-items: center;

  .checkbox {
    margin-left: 10px;
  }
`;

const Label = styled.p.attrs({ className: "checkbox-array__label" })`
  margin: 0;
  margin-top: 3px;
  margin-left: 8px;
  cursor: pointer;
`;

export const CheckboxArray = ({ position, label, numOfElements, onChange }) => {
  return (
    <Container>
      <Label>{label}</Label>
      {Array.from(Array(numOfElements)).map((_, index) => {
        const selected = index === position;

        return (
          <Checkbox
            key={index}
            value={selected}
            onChange={() => onChange(selected ? -1 : index)}
          />
        );
      })}
    </Container>
  );
};
