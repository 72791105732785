import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { actionDispatch } from "../../../appState/actions";
import { useAppDispatch, useAppState } from "../../../appState/appState";
import { colors } from "../../../styles/colors";
import { Icon } from "../../Icon/Icon";

const flipAnimation = keyframes`
    from {
        transform: rotateY(0deg);
    }

    to {
        transform: rotateY(360deg);
    }
`;

const opacityAnimation = keyframes`
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  cursor: ${({ items }) => (items ? "pointer" : "not-allowed")};

  * {
    cursor: inherit;
  }
`;

const Perspective = styled.div`
  position: absolute;
  right: 5px;
  bottom: 8px;
  width: 22px;
  height: 22px;
  perspective: 50px;
`;

const ItemsContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${colors.red};
  border-radius: 50%;
  transform-style: preserve-3d;

  &.animate {
    animation: ${flipAnimation} 1s ease forwards;
  }
`;

const Items = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  color: white;

  &.animate {
    animation: ${opacityAnimation} 0.5s ease alternate;
  }
`;

const useNumberOfCartItems = () => {
  const {
    cart: { items },
  } = useAppState();

  return items.length
    ? items.reduce((acc, item) => {
        return acc + item.nrOfItems;
      }, 0)
    : 0;
};

const useOnShowCart = () => {
  const dispatch = useAppDispatch();

  return () => dispatch(actionDispatch.cart.setVisibility(true));
};

export const CartButton = () => {
  const items = useNumberOfCartItems();
  const [displayItems, setDisplayItems] = useState(items);
  const [animate, setAnimate] = useState(false);
  const didMountRef = useRef(false);
  const onShowCart = useOnShowCart();

  useEffect(() => {
    if (didMountRef.current) {
      setAnimate(true);

      setTimeout(() => {
        setDisplayItems(items);
      }, 500);

      setTimeout(() => {
        setAnimate(false);
      }, 1500);
      return;
    }

    didMountRef.current = true;
  }, [items]);

  return (
    <Container items={items} onClick={items ? onShowCart : undefined}>
      <Icon icon="cart" />

      {!!items && (
        <Perspective>
          <ItemsContainer className={animate && "animate"}>
            <Items className={animate && "animate"}>{displayItems}</Items>
          </ItemsContainer>
        </Perspective>
      )}
    </Container>
  );
};
