import React from "react";
import styled from "styled-components";
import { useAppState, useRequestProducts } from "../../appState/appState";
import { Button } from "../../components/Inputs/Button";

const LoadMoreButton = styled(Button).attrs({
  className: "load-more-products-button",
  text: "INCARCA MAI MULTE PRODUSE",
})``;

export const LoadMoreProductsButton = () => {
  const lastProductItem = useAppState().lastProductItem;
  const requestProducts = useRequestProducts();

  return (
    lastProductItem && <LoadMoreButton onClick={() => requestProducts()} />
  );
};
