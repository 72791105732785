import uniqId from "uniqid";
import { updateLocalStorage } from "../utils";
import { actionsTypes } from "./actions";
import { initialState } from "./appState";

export const loadingInitialState = false;

const loadingReducer = (state, action) => {
  const { type } = action;

  switch (type) {
    case actionsTypes.loading.hide:
      return false;
    case actionsTypes.loading.show:
      return true;

    default:
      return state;
  }
};

const notificationsReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionsTypes.notifications.setMessage:
      return {
        messageId: state.messageId + 1,
        message: payload,
      };

    default:
      return state;
  }
};

const userReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionsTypes.user.setData:
      return {
        ...payload,
      };
    case actionsTypes.user.clearData:
      return {};

    case actionsTypes.user.addFavoriteProduct:
      return {
        ...state,
        favorite: [...state.favorite, payload],
      };

    case actionsTypes.user.removeFavoriteProduct:
      return {
        ...state,
        favorite: state.favorite.filter((item) => item !== payload),
      };

    default:
      return state;
  }
};

const productsFiltersReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionsTypes.productsFilters.reset:
      const resettedFilters = ["category", "type", "brand", "price"].reduce(
        (acc, filterName) => {
          acc[filterName] = state[filterName].map((filterItem) => ({
            ...filterItem,
            value: false,
          }));

          return acc;
        },
        {}
      );

      return {
        ...state,
        ...resettedFilters,
      };

    case actionsTypes.productsFilters.setBrand:
      return {
        ...state,
        brand: state.brand.map((filter) => {
          if (filter.id === payload.id) {
            return {
              ...filter,
              value: payload.value,
            };
          }

          return filter;
        }),
      };

    case actionsTypes.productsFilters.setType:
      return {
        ...state,
        type: state.type.map((filter) => {
          if (filter.id === payload.id) {
            return {
              ...filter,
              value: payload.value,
            };
          }

          return filter;
        }),
      };

    case actionsTypes.productsFilters.setCategory:
      return {
        ...state,
        category: state.category.map((filter) => {
          if (filter.id === payload.id) {
            return {
              ...filter,
              value: payload.value,
            };
          }

          return filter;
        }),
      };

    case actionsTypes.productsFilters.setPrice:
      return {
        ...state,
        price: state.price.map((filter) => {
          if (filter.id === payload.id) {
            return {
              ...filter,
              value: payload.value,
            };
          }

          return filter;
        }),
      };

    case actionsTypes.productsFilters.setFavorite:
      return {
        ...state,
        favorite: payload,
      };

    default:
      return state;
  }
};

const productsSortReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionsTypes.productsSort.reset:
      return {
        type: null,
        direction: null,
      };

    case actionsTypes.productsSort.setByPrice:
      return {
        type: "price",
        direction: payload,
      };

    case actionsTypes.productsSort.setByDate:
      return {
        type: "date",
        direction: payload,
      };

    default:
      return state;
  }
};

const productsReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionsTypes.products.set:
      return payload;

    case actionsTypes.products.add:
      return [...state, ...payload];

    case actionsTypes.products.removeProduct:
      return state.filter(({ id }) => id !== payload);

    default:
      return state;
  }
};

const lastProductItemReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionsTypes.lastProductItem.set:
      return payload;

    case actionsTypes.lastProductItem.unset:
      return null;

    default:
      return state;
  }
};

const editProductReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionsTypes.editProduct.setEditValues:
      return payload;

    case actionsTypes.editProduct.setField:
      const { fieldName, value } = payload;
      return {
        ...state,
        [fieldName]: value,
      };

    case actionsTypes.editProduct.addEmptySpecSection:
      return {
        ...state,
        specs: [
          ...state.specs,
          {
            id: uniqId(),
            name: "",
            specList: [],
          },
        ],
      };

    case actionsTypes.editProduct.addEmptySpecItem:
      return {
        ...state,
        specs: state.specs.map((spec) => {
          const { id, name, specList } = spec;

          if (id === payload) {
            return {
              id,
              name,
              specList: [
                ...specList,
                {
                  id: uniqId(),
                  name: "",
                  value: "",
                },
              ],
            };
          }

          return spec;
        }),
      };

    case actionsTypes.editProduct.removeSection:
      return {
        ...state,
        specs: state.specs.filter((spec) => spec.id !== payload),
      };

    case actionsTypes.editProduct.removeSectionItem:
      return {
        ...state,
        specs: state.specs.map((spec) => {
          const { id, specList } = spec;

          if (id === payload.sectionId) {
            return {
              ...spec,
              specList: specList.filter(({ id }) => id !== payload.itemId),
            };
          }

          return spec;
        }),
      };

    case actionsTypes.editProduct.editSpecSectionName:
      return {
        ...state,
        specs: state.specs.map((spec) => {
          const { id } = spec;

          if (id === payload.id) {
            return {
              ...spec,
              name: payload.value,
            };
          }

          return spec;
        }),
      };

    case actionsTypes.editProduct.editSpecName:
      return {
        ...state,
        specs: state.specs.map((spec) => {
          const { id, specList } = spec;

          if (id === payload.sectionId) {
            return {
              ...spec,
              specList: specList.map((spec) => {
                const { id } = spec;

                if (id === payload.specId) {
                  return {
                    ...spec,
                    name: payload.value,
                  };
                }

                return spec;
              }),
            };
          }

          return spec;
        }),
      };

    case actionsTypes.editProduct.editSpecValue:
      return {
        ...state,
        specs: state.specs.map((spec) => {
          const { id, specList } = spec;

          if (id === payload.sectionId) {
            return {
              ...spec,
              specList: specList.map((spec) => {
                const { id } = spec;

                if (id === payload.specId) {
                  return {
                    ...spec,
                    value: payload.value,
                  };
                }

                return spec;
              }),
            };
          }

          return spec;
        }),
      };

    case actionsTypes.editProduct.addImg:
      return {
        ...state,
        imgs: [
          ...state.imgs,
          {
            id: uniqId(),
            src: payload.data,
            type: payload.type,
            isCover: state.imgs.length ? false : true,
          },
        ],
      };

    case actionsTypes.editProduct.removeImg:
      return {
        ...state,
        imgs: state.imgs.filter((img) => img.id !== payload),
      };

    case actionsTypes.editProduct.setCoverImg:
      return {
        ...state,
        imgs: state.imgs.map((img) => {
          if (img.id === payload) {
            return {
              ...img,
              isCover: true,
            };
          } else if (img.isCover === true) {
            return {
              ...img,
              isCover: false,
            };
          }

          return img;
        }),
      };

    case actionsTypes.editProduct.removeId:
      return {
        ...state,
        id: null,
      };

    case actionsTypes.editProduct.clear:
      return {
        ...initialState.editProduct,
      };

    default:
      return state;
  }
};

const cartReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionsTypes.cart.addItem: {
      let newState;

      if (state.items.find(({ id }) => payload.id === id)) {
        newState = state.items.map((item) => {
          if (item.id === payload.id) {
            return {
              ...item,
              nrOfItems: item.nrOfItems + 1,
            };
          }

          return item;
        });
      } else {
        newState = [...state.items, { ...payload, nrOfItems: 1 }];
      }

      updateLocalStorage("ayo-cart", newState);
      return {
        ...state,
        items: newState,
      };
    }

    case actionsTypes.cart.removeItem: {
      const newState = state.items.filter(({ id }) => id !== payload);
      updateLocalStorage("ayo-cart", newState);
      return {
        ...state,
        items: newState,
      };
    }

    case actionsTypes.cart.updateNrOfItems: {
      const { id, value } = payload;
      if (value !== 0) {
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                nrOfItems: value,
              };
            }

            return item;
          }),
        };
      }

      return state;
    }

    case actionsTypes.cart.setVisibility: {
      return {
        ...state,
        visibility: payload,
      };
    }

    case actionsTypes.cart.reset: {
      updateLocalStorage("ayo-cart", []);
      return {
        ...state,
        items: [],
      };
    }

    default:
      return state;
  }
};

export const reducers = (state, action) => {
  const loading = loadingReducer(state.loading, action);
  const notifications = notificationsReducer(state.notifications, action);
  const user = userReducer(state.user, action);
  const productsFilters = productsFiltersReducer(state.productsFilters, action);
  const productsSort = productsSortReducer(state.productsSort, action);
  const products = productsReducer(state.products, action);
  const lastProductItem = lastProductItemReducer(state.lastProductItem, action);
  const editProduct = editProductReducer(state.editProduct, action);
  const cart = cartReducer(state.cart, action);

  return {
    loading,
    notifications,
    user,
    productsFilters,
    productsSort,
    products,
    lastProductItem,
    editProduct,
    cart,
  };
};
