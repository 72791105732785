import React from "react";
import styled from "styled-components";
import { actionDispatch } from "../../appState/actions";
import { useAppDispatch } from "../../appState/appState";
import { colors } from "../../styles/colors";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const IncrementButton = styled.button`
  padding: 0;
  margin: 0 5px;
  border: none;
  background: transparent;
  cursor: pointer;
`;

const NrOfItems = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid ${colors.grey};
`;

const NrOfItemsText = styled.p`
  margin: 0;
  font-size: 12px;
`;

const useUpdateNrOfItems = (id) => {
  const dispatch = useAppDispatch();

  return (value) =>
    dispatch(actionDispatch.cart.updateNumberOfItems(id, value));
};

export const CartItemCount = ({ id, nrOfItems }) => {
  const onUpdateNrOfItems = useUpdateNrOfItems(id);

  return (
    <Container>
      <IncrementButton onClick={() => onUpdateNrOfItems(nrOfItems - 1)}>
        -
      </IncrementButton>
      <NrOfItems>
        <NrOfItemsText>{nrOfItems}</NrOfItemsText>
      </NrOfItems>
      <IncrementButton onClick={() => onUpdateNrOfItems(nrOfItems + 1)}>
        +
      </IncrementButton>
    </Container>
  );
};
