import React from "react";
import styled from "styled-components";

import { ProfileButton } from "./ProfileButton";
import { Icon } from "../../Icon/Icon";
import { FavoriteButton } from "./FavoriteButton";
import { CartButton } from "./CartButton";

const HederShortcutsContainer = styled.div.attrs({
  className: "header-shortcuts",
})`
  display: flex;

  .icon {
    margin: 10px;
  }
`;

export const HederShortcuts = () => {
  return (
    <HederShortcutsContainer>
      <Icon icon="search" />
      <ProfileButton />
      <FavoriteButton />
      <CartButton />
    </HederShortcutsContainer>
  );
};
