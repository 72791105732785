import React from "react";
import styled from "styled-components";
import { useRequestProductsEffect } from "../../appState/helpers";
import { Page } from "../Page";
import { ProductsBanner } from "./ProductsBanner";
import { ProductsBody } from "./ProductsBody";

const ProductsContainer = styled(Page).attrs({ className: "products-page" })`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Products = () => {
  const loading = useRequestProductsEffect();

  return (
    <ProductsContainer>
      <ProductsBanner />
      <ProductsBody loading={loading} />
    </ProductsContainer>
  );
};
