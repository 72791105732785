import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { AppLogo } from "../AppLogo";

const FooterContainer = styled.div.attrs({
  className: "footer-container",
})`
  border-top: 1px solid ${colors.lightgrey};
  padding: 20px;
  margin: 80px 160px 10px 160px;

  display: flex;

  .app-logo {
    margin: 15px;
    margin-left: 0;
  }
`;

const FooterSections = styled.div.attrs({
  className: "footer-sections",
})`
  display: flex;
  margin-left: auto;
`;

const FooterSection = styled.div.attrs({
  className: "footer-section",
})`
  margin-left: 60px;
`;

const FooterSectionTitle = styled.h3.attrs({
  className: "footer-title",
})``;

const FooterSectionText = styled.a.attrs({
  className: "footer-text",
  target: "_blank",
})`
  display: block;
  cursor: pointer;
  margin: 10px 0px;
  color: black;

  &:hover {
    color: blue;
    text-decoration: underline;
  }
`;

export const Footer = () => (
  <FooterContainer>
    <AppLogo size={100} />
    <FooterSections>
      <FooterSection>
        <FooterSectionTitle>CONTACT</FooterSectionTitle>
        <FooterSectionText>Nume: Colnita Ovidiu</FooterSectionText>
        <FooterSectionText>Mobil: +40742389898</FooterSectionText>
        <FooterSectionText>E-mail: ayotek.service@gmail.com</FooterSectionText>
        <FooterSectionText>
          Adresa: Floresti, Urusagului, 19A, Cluj
        </FooterSectionText>
      </FooterSection>
      <FooterSection>
        <FooterSectionTitle>INFORMATII</FooterSectionTitle>
        <FooterSectionText href="/pdf/politica-de-confidentialitate.pdf">
          Politica de confidentialitate
        </FooterSectionText>
        <FooterSectionText href="/pdf/termeni-si-conditii.pdf">
          Termeni si conditii
        </FooterSectionText>
        <FooterSectionText href="/pdf/politica-de-retur.pdf">
          Politica de retur
        </FooterSectionText>
      </FooterSection>
    </FooterSections>
  </FooterContainer>
);
