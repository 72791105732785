import React, { useState } from "react";
import { isEmpty } from "lodash";
import styled from "styled-components";
import { actionDispatch } from "../../appState/actions";
import { useAppDispatch } from "../../appState/appState";
import { Button } from "../../components/Inputs/Button";
import { TextArea } from "../../components/Inputs/TextArea";
import { TextField } from "../../components/Inputs/TextField";
import { colors } from "../../styles/colors";
import { sendEmail } from "../../utils";
import { EmailSendMessage } from "./EmailSendMessage";

const Container = styled.div`
  margin-top: 50px;
  padding: 50px 0px;
  border-top: 1px solid ${colors.grey};
`;

const Title = styled.h3`
  margin: 0;
`;

const Form = styled.form`
  padding: 50px;
  background: ${colors.green};
  border-radius: 40px;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
  overflow: hidden;
`;

const SendButton = styled(Button)`
  align-self: flex-end;
`;

const isInvalidField = (fieldName, fieldValue) => {
  switch (fieldName) {
    case "email":
      return !/.+@.+\..+/.test(fieldValue);

    default:
      return !fieldValue;
  }
};

const getInvalidFields = (formData) => {
  return Object.keys(formData).reduce((acc, key) => {
    if (isInvalidField(key, formData[key])) {
      acc[key] = true;
    }

    return acc;
  }, {});
};

const clearFormData = (formData) => {
  return Object.keys(formData).reduce((acc, key) => (acc[key] = "") || acc, {});
};

export const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [inValidFormFields, setInvalidFormFields] = useState({});
  const [messageSent, setMessageSent] = useState("");
  const dispatch = useAppDispatch();

  const setField = (fieldName, fieldValue) => {
    setInvalidFormFields({});
    setFormData({
      ...formData,
      [fieldName]: fieldValue,
    });
  };

  const onSubmit = () => {
    const invalidFormData = getInvalidFields(formData);
    setInvalidFormFields(invalidFormData);

    if (isEmpty(invalidFormData)) {
      dispatch(actionDispatch.loading.setVisibility(true));
      sendEmail(formData)
        .then(() => {
          setFormData(clearFormData(formData));
          setMessageSent("Mesajul a fost transmis cu succes");
          setTimeout(() => {
            setMessageSent("");
          }, 2000);
        })
        .finally(() => dispatch(actionDispatch.loading.setVisibility(false)));
    }
  };

  return (
    <Container>
      <Title>Trimite-ne un mesaj</Title>
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          onSubmit();
        }}
      >
        <TextField
          label="NUME"
          color="white"
          value={formData.name}
          invalid={!!inValidFormFields.name}
          onChange={(value) => setField("name", value)}
        />
        <TextField
          label="E-MAIL"
          color="white"
          value={formData.email}
          invalid={!!inValidFormFields.email}
          onChange={(value) => setField("email", value)}
        />
        <TextField
          label="SUBIECT"
          color="white"
          value={formData.subject}
          invalid={!!inValidFormFields.subject}
          onChange={(value) => setField("subject", value)}
        />
        <TextArea
          label="MESAJ"
          color="white"
          value={formData.message}
          invalid={!!inValidFormFields.message}
          onChange={(value) => setField("message", value)}
        />
        <SendButton secondary text="Trimite" />

        {messageSent && (
          <EmailSendMessage message={messageSent}></EmailSendMessage>
        )}
      </Form>
    </Container>
  );
};
