import { round } from "lodash";
import React from "react";
import styled from "styled-components";
import { useAppState } from "../../appState/appState";

const Container = styled.div`
  display: flex;
  padding: 20px 0px;
  justify-content: ${({ left }) => (left ? "flex-end" : "space-between")};

  h3:first-child {
    margin-right: 10px;
  }
`;

export const CartTotalPrice = ({ left }) => {
  const {
    cart: { items },
  } = useAppState();

  const totalPrice = items.reduce((acc, item) => {
    const finalPrice = round(
      !!item.discount
        ? item.price - item.price * (item.discount / 100)
        : item.price,
      2
    );
    acc = acc + finalPrice * (item.nrOfItems || 1);

    return acc;
  }, 0);

  return (
    <Container left={left}>
      <h3>TOTAL:</h3>
      <h3>{round(totalPrice, 2)} Ron</h3>
    </Container>
  );
};
