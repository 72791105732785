import { useEffect, useRef } from "react";
import { useAppState } from "../appState/appState";
import Resizer from "react-image-file-resizer";

const MAX_WIDTH_IMG = 1440;
const MAX_HEIGHT_IMG = 788;

export const useIsUserLogged = () => {
  const { id } = useAppState().user;

  return !!id;
};

export const getUserData = () => {
  const userData = localStorage.getItem("user");

  if (userData) {
    return JSON.parse(userData);
  }

  return {};
};

export const getSortParam = (sort) => {
  if (sort && sort.type && sort.direction) {
    const { type, direction } = sort;

    return `${type}-${direction}`;
  }

  return null;
};

export const useClickOutside = (callback) => {
  const ref = useRef(null);
  const clickOutsideCallBack = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  };
  useEffect(() => {
    if (ref.current && callback) {
      window.addEventListener("click", clickOutsideCallBack);
    }

    return () => {
      window.removeEventListener("click", clickOutsideCallBack);
    };
    // eslint-disable-next-line
  }, [callback]);

  return ref;
};

export const resizeImg = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      MAX_WIDTH_IMG,
      MAX_HEIGHT_IMG,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export const generateAddProductPayload = (data) => {
  return {
    ...data,
    price: Number(data.price) || 0,
    stockValue: Number(data.stockValue) || 0,
    discount: Number(data.discount) || 0,
    warranty: Number(data.warranty) || 0,
  };
};

export const updateLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};
