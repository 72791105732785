import React, { useRef } from "react";
import styled from "styled-components";
import { Icon } from "../Icon/Icon";
import { ImgsActions } from "./ImgsActions";
import { UploadImgButton } from "./UploadImgButton";

const Container = styled.div.attrs({ className: "product__img-carousel" })`
  display: flex;
  align-items: center;
  height: 190px;
`;

const ImgContainer = styled.div.attrs({
  className: "img-carousel__img-container",
})`
  display: flex;
  flex: 1;
  overflow-y: none;
  overflow-x: auto;
  padding: 20px 0px;
  padding-right: 10px;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const CarouselImg = styled.img.attrs({
  className: "img-carousel__carousel-img",
})`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CarouselImgContainer = styled.div.attrs({
  className: "img-carousel__carousel-img",
})`
  width: 130px;
  height: 130px;
  border-radius: 20px;
  box-shadow: 5px 5px 20px
    rgba(0, 0, 0, ${({ isSelected }) => (isSelected ? 0.18 : 0.1)});
  margin-left: 10px;
  overflow: hidden;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  padding: 10px;
  transition: box-shadow 0.3s ease;
  background: white;
`;

const MoveButton = styled(Icon).attrs({ className: "move-button" })`
  margin: 0px;
`;

const handleMoveRight = (ref) => {
  return () => {
    if (ref.current) {
      ref.current.scrollBy({
        left: 141,
        behavior: "smooth",
      });
    }
  };
};

const handleMoveLeft = (ref) => {
  return () => {
    if (ref.current) {
      ref.current.scrollBy({
        left: -141,
        behavior: "smooth",
      });
    }
  };
};

export const ImgCarousel = ({ imgs, currentImg, setCurrentImg, viewOnly }) => {
  const ref = useRef(null);

  return (
    <Container>
      {!viewOnly && <UploadImgButton />}

      <MoveButton icon="previous" onClick={handleMoveLeft(ref)} />
      <ImgContainer ref={ref}>
        {imgs.map(({ src, id, isCover }) => (
          <CarouselImgContainer key={id} isSelected={currentImg === src}>
            {!viewOnly && <ImgsActions id={id} isCover={isCover} />}
            <CarouselImg src={src} onClick={() => setCurrentImg(src)} />
          </CarouselImgContainer>
        ))}
      </ImgContainer>
      <MoveButton icon="next" onClick={handleMoveRight(ref)} />
    </Container>
  );
};
