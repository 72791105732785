import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { IconButton } from "../../components/Inputs/IconButton";
import { Button } from "../../components/Inputs/Button";
import { FavoriteButton } from "../../components/FavoriteButton";
import { useHandleRequestFavoriteProduct } from "../../appState/helpers";
import { useAppDispatch, useAppState } from "../../appState/appState";
import { actionDispatch } from "../../appState/actions";
import { useHistory } from "react-router-dom";
import { round } from "lodash";

const Container = styled.div.attrs({ className: "product-list__product-item" })`
  display: flex;
  background: white;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 30px;
  position: relative;
  overflow: hidden;
  height: ${({ promoLayout }) => (promoLayout ? 400 : 300)}px;

  .favorite-button {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;

const CoverImgContainer = styled.div`
  flex: 1;
`;

const CoverImg = styled.img.attrs(({ src }) => ({
  className: "product-list__product-item__cover",
  src: src || "/img/product-item-cover.png",
}))`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ItemDetails = styled.div.attrs({
  className: "product-list__product-item__details",
})`
  display: flex;
  flex-direction: column;
  flex: 2;
  border-left: 2px solid ${colors.lightgrey};
  margin-left: 20px;
  padding: 0px 20px;
`;

const Text = styled.h3.attrs({
  className: "product-list__product-item__text",
})`
  margin: 0;
  margin-bottom: 10px;
`;

const SubText = styled.p.attrs({
  className: "product-list__product-item__text",
})`
  margin: 0;
  color: ${colors.grey};
  margin-bottom: 10px;
`;

const ItemName = styled(Text).attrs({
  className: "product-list__product-item__name",
})`
  width: 100%;
  max-height: 50%;
  overflow: hidden;
  margin-bottom: 10px;
  font-size: ${({ promoLayout }) => (promoLayout ? "20px" : "inherited")};
`;

const Section = styled.div.attrs({
  className: "product-list__product-item__section",
})`
  display: flex;
  flex: 1;
`;

const SectionColumn = styled(Section)`
  flex-direction: column;
  justify-content: flex-end;
`;

const PriceValue = styled(Text).attrs({
  className: "product-list__product-item__price-value",
})`
  color: ${colors.red};
  margin-bottom: 0px;
  font-size: 22px;
`;

const ItemActions = styled(SectionColumn).attrs({
  className: "product-list__product-item__actions",
})`
  align-items: flex-end;

  .button {
    margin-top: 10px;
  }
`;

const GoToProductButton = styled(Button).attrs({
  className: "product-list__product-item__actions",
  secondary: true,
  text: "Citeste mai mult",
})``;

const DescriptionText = styled.p`
  margin: 20px 0px 6px 0px;
  font-size: 16px;
  overflow: hidden;
  max-height: 70px;
`;

const DiscountText = styled.p`
  font-size: 16px;
  background: #e43333;
  margin: 0px;
  padding: 5px 58px;
  color: white;
  position: absolute;
  top: 16px;
  left: -59px;
  transform: rotate(-45deg);
`;

const PriceDiscount = styled.p`
  margin: 0px;
  text-decoration: line-through;
`;

const useOnEditProduct = (props, isCopyProduct) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const productAttributes = Object.keys(useAppState().editProduct);

  return () => {
    const editProductState = productAttributes.reduce((acc, attributeName) => {
      acc[attributeName] = props[attributeName];

      return acc;
    }, {});

    dispatch(actionDispatch.editProduct.setEditValues(editProductState));

    if (isCopyProduct) {
      history.push("/admin/adauga_produse", { copy: true });
    } else {
      history.push(`/admin/editeaza_produse/${editProductState.id}`);
    }
  };
};

const useGoToProduct = (productId) => {
  const history = useHistory();

  return () => {
    history.push(`/produse/${productId}`);
  };
};

const useAddToCart = (item) => {
  const dispatch = useAppDispatch();

  return () => dispatch(actionDispatch.cart.addItem(item));
};

export const ProductItem = (props) => {
  const {
    id,
    name,
    stockStatus,
    price,
    favoriteProduct,
    imgs,
    description,
    promoLayout,
    discount,
  } = props;
  const { isAdmin } = useAppState().user;

  const handleClickOnFavButton = useHandleRequestFavoriteProduct(
    id,
    favoriteProduct
  );

  const onEditProduct = useOnEditProduct(props);
  const onCopyProduct = useOnEditProduct(props, true);
  const onGoToProduct = useGoToProduct(id);
  const onAddToCart = useAddToCart(props);

  const { src: coverImgSrc } =
    imgs.find(({ isCover }) => isCover === true) || {};

  return (
    <Container promoLayout={promoLayout}>
      <CoverImgContainer>
        <CoverImg src={coverImgSrc} />
      </CoverImgContainer>
      <ItemDetails>
        <ItemName promoLayout>{name}</ItemName>
        <DescriptionText>{description}</DescriptionText>
        <Section>
          <SectionColumn>
            <SubText>{stockStatus}</SubText>
            <Text>PRET</Text>
            {!!discount && <PriceDiscount>{price} RON</PriceDiscount>}
            <PriceValue>
              {round(!!discount ? price - price * (discount / 100) : price, 2)}
              RON
            </PriceValue>
          </SectionColumn>
          <ItemActions>
            <GoToProductButton onClick={onGoToProduct} />
            {isAdmin ? (
              <>
                <Button text="Copiaza" onClick={onCopyProduct} />
                <Button text="Editeaza" onClick={onEditProduct} />
              </>
            ) : (
              <IconButton
                text="Adauga in cos"
                icon="cart-white"
                onClick={onAddToCart}
              />
            )}
          </ItemActions>
        </Section>
      </ItemDetails>
      <FavoriteButton
        selected={favoriteProduct}
        onClick={handleClickOnFavButton}
      />
      {discount ? <DiscountText>reducere</DiscountText> : null}
    </Container>
  );
};
