import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Icon } from "./Icon/Icon";
import { colors } from "../styles/colors";

const BackButtonContainer = styled.div.attrs({ className: "back-button" })`
  display: flex;
  align-items: center;
`;
const BackButtonText = styled.p`
  color: ${colors.green};
  margin: 0;
  margin-top: 2px;
`;

export const BackButton = ({ children, path }) => (
  <Link to={path}>
    <BackButtonContainer>
      <Icon icon="back" size={35} />
      <BackButtonText>{children}</BackButtonText>
    </BackButtonContainer>
  </Link>
);
