import React from "react";
import styled from "styled-components";
import { ProductImages } from "../../components/ProductImages/ProductImages";
import { Page } from "../Page";
import { useGetProductData } from "./ProductHooks";
import { ProductMainInfo } from "./ProductMainInfo";
import { ProductSpecifications } from "./ProductSpecifications";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionRow = styled(Page).attrs({ className: "product__section-row" })`
  flex-direction: row;
`;

export const Product = () => {
  const product = useGetProductData();
  const { imgs, specs } = product;

  return (
    <Container>
      <SectionRow>
        <ProductImages imgs={imgs || []} viewOnly />
        <ProductMainInfo {...product} />
      </SectionRow>
      <ProductSpecifications specs={specs} />
    </Container>
  );
};
