import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { useClickOutside } from "../../utils";
import { AddButton } from "../AddButton";
import { EditField } from "../Inputs/EditField";

const Container = styled.div`
  width: 100%;
  height: ${({ opened }) => (opened ? "unset" : "40px")};
  box-sizing: border-box;
  border-radius: ${({ opened }) => (opened ? "20px" : "40px")};
  font-size: 20px;
  padding: 0px 20px;
  border: 1px solid black;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  z-index: ${({ opened }) => (opened ? "2" : "unset")};
`;

const SelectedValue = styled.p`
  margin: 0;
  padding: 7px 0px;
  width: calc(100% - 10px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 25px;
`;

const OptionsContainer = styled.div`
  width: 100%;
  padding-top: 7px;
  border-top: 1px solid ${colors.grey};
`;

const Item = styled.p`
  margin: 10px 0px;

  &:first-child {
    margin-top: 0px;
  }
`;

const AddOptionContainer = styled.div.attrs({
  className: "dropdown__add-option-container",
})`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  align-items: center;

  &.dropdown__add-option-container .edit-field {
    margin-bottom: 0px;
  }

  .icon {
    margin-left: -9px;
  }

  .edit-field input {
    box-sizing: border-box;
    width: 100%;
  }
`;

export const Flyout = ({
  opened,
  selectedValue,
  options: optionsProps,
  onSelectedValueClick,
  onClickOutSide,
  onSelectItem,
  addField,
}) => {
  const [options, setOptions] = useState(optionsProps);
  const [addOptionValue, setAddOptionValue] = useState("");
  const ref = useClickOutside(onClickOutSide);
  const onSubmit = () => {
    onSelectItem(addOptionValue);
    setAddOptionValue("");
    setOptions([...options, addOptionValue]);
    if (onClickOutSide) onClickOutSide();
  };

  useEffect(() => {
    setOptions(optionsProps);
  }, [optionsProps]);

  return (
    <Container ref={ref} opened={opened}>
      <SelectedValue onClick={opened ? onSelectedValueClick : undefined}>
        {selectedValue}
      </SelectedValue>
      {opened && options && (
        <OptionsContainer>
          {options
            .filter((item) => item !== selectedValue)
            .map((item) => (
              <Item key={item} onClick={() => onSelectItem(item)}>
                {item}
              </Item>
            ))}

          {addField && (
            <AddOptionContainer>
              <AddButton onClick={onSubmit} />
              <EditField
                onEnter={onSubmit}
                value={addOptionValue}
                onChange={(value) => setAddOptionValue(value)}
              />
            </AddOptionContainer>
          )}
        </OptionsContainer>
      )}
    </Container>
  );
};
