import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { actionDispatch } from "../../../appState/actions";
import { useAppDispatch, useAppState } from "../../../appState/appState";
import { handleRequestProducts } from "../../../appState/helpers";
import { useIsUserLogged } from "../../../utils";
import { Icon } from "../../Icon/Icon";

const useGetIconType = () => {
  const { user, productsFilters } = useAppState();

  const { favorite: favoriteView } = productsFilters;
  const { favorite: userFavorites } = user;

  if (favoriteView) {
    if (userFavorites && userFavorites.length) return "favorite-full-red-dot";
    return "favorite-full";
  }

  if (userFavorites && userFavorites.length) return "favorite-red-dot";

  return "favorite";
};

const useRequestFavoriteProducts = () => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const { productsFilters } = state;
  const requestProducts = handleRequestProducts(state, dispatch);

  const { favorite: favoriteView } = productsFilters;

  useEffect(() => {
    if (favoriteView) {
      requestProducts();
    }
    // eslint-disable-next-line
  }, [favoriteView]);
};

const useHandleMoveOutFromProductsPage = () => {
  const { pathname } = useLocation();
  const { favorite: favoriteView } = useAppState().productsFilters;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!pathname.includes("produse") && favoriteView) {
      dispatch(actionDispatch.productsFilters.setFavorite(false));
    }
    // eslint-disable-next-line
  }, [pathname, favoriteView]);
};

export const FavoriteButton = () => {
  const isUserLogged = useIsUserLogged();
  const icon = useGetIconType();
  const history = useHistory();
  const dispatch = useAppDispatch();
  useRequestFavoriteProducts();
  useHandleMoveOutFromProductsPage();

  return isUserLogged ? (
    <Icon
      icon={icon}
      onClick={() => {
        dispatch(actionDispatch.productsFilters.setFavorite(true));
        history.push("/produse");
      }}
    />
  ) : null;
};
