import React, { useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppState } from "../../../appState/appState";
import { Modal } from "../../../components/Modal";
import { TextField } from "../../../components/Inputs/TextField";
import { isEmpty, omit } from "lodash";
import { getUserDetails, updateUserData } from "../../../utils";
import { actionDispatch } from "../../../appState/actions";

const FieldContainer = styled.div.attrs({ className: "field-container" })`
  display: flex;

  .text-field-container {
    margin: 0px 10px 20px 10px;
  }
`;

const handleUserUpdate =
  (fields, setInValidFields, onSave, dispatch) => async () => {
    const invalidFields = {};

    for (const fieldName in fields) {
      const fieldValue = fields[fieldName];
      if (!fieldValue) invalidFields[fieldName] = true;
    }

    if (isEmpty(invalidFields)) {
      const payload = {
        ...fields,
        phone: fields.mobile,
        zipCode: Number(fields.zip) || 0,
      };
      delete payload.mobile;
      delete payload.zip;

      dispatch(actionDispatch.loading.setVisibility(true));
      try {
        await updateUserData(payload);
        const userData = await getUserDetails();
        dispatch(actionDispatch.user.setData(userData));
        onSave();
        dispatch(actionDispatch.loading.setVisibility(false));
      } catch (error) {
        console.log(error);
        dispatch(actionDispatch.loading.setVisibility(false));
        dispatch(actionDispatch.notifications.setMessage("Actualizare esuata"));
      }
    } else {
      setInValidFields(invalidFields);
    }
  };

export const EditProfileModal = ({ visibility, onClose, onSave }) => {
  const {
    firstName: currentFirstName,
    lastName: currentLastName,
    email: currentEmail,
    phone: currentPhone,
    country: currentCountry,
    county: currentCounty,
    city: currentCity,
    address: currentAddress,
    zipCode: currentZipCode,
  } = useAppState().user;

  const dispatch = useAppDispatch();

  const [email, setEmail] = useState(currentEmail || "");
  const [lastName, setLastName] = useState(currentLastName || "");
  const [firstName, setFirstName] = useState(currentFirstName || "");
  const [country, setCountry] = useState(currentCountry || "Romania");
  const [county, setCounty] = useState(currentCounty || "");
  const [city, setCity] = useState(currentCity || "");
  const [address, setAddress] = useState(currentAddress || "");
  const [zip, setZip] = useState(currentZipCode | "");
  const [mobile, setMobile] = useState(currentPhone || "");

  const fields = {
    email,
    lastName,
    country,
    county,
    city,
    address,
    zip,
    mobile,
  };

  const [inValidFields, setInValidFields] = useState({});

  const onUserUpdate = handleUserUpdate(
    fields,
    setInValidFields,
    onSave,
    dispatch
  );

  return (
    <Modal
      visibility={visibility}
      onSecondaryButtonClick={onClose}
      onPrimaryButtonClick={onUserUpdate}
      modalTitle="Editeaza informatiile contului"
    >
      <FieldContainer>
        <TextField
          label="Email"
          onChange={(value) => {
            setEmail(value);
            if (value) setInValidFields(omit(inValidFields, ["email"]));
          }}
          value={email}
          invalid={inValidFields.email}
        />
      </FieldContainer>
      <FieldContainer>
        <TextField
          label="Nume"
          onChange={(value) => {
            setLastName(value);
            if (value) setInValidFields(omit(inValidFields, ["lastName"]));
          }}
          value={lastName}
          invalid={inValidFields.lastName}
        />
        <TextField
          label="Prenume"
          onChange={(value) => {
            setFirstName(value);
            if (value) setInValidFields(omit(inValidFields, ["firstName"]));
          }}
          value={firstName}
          invalid={inValidFields.firstName}
        />
      </FieldContainer>
      <FieldContainer>
        <TextField
          label="Tara"
          onChange={(value) => {
            setCountry(value);
            if (value) setInValidFields(omit(inValidFields, ["country"]));
          }}
          value={country}
          invalid={inValidFields.country}
        />
        <TextField
          label="Judet"
          onChange={(value) => {
            setCounty(value);
            if (value) setInValidFields(omit(inValidFields, ["county"]));
          }}
          value={county}
          invalid={inValidFields.county}
        />
      </FieldContainer>
      <FieldContainer>
        <TextField
          label="Oras"
          onChange={(value) => {
            setCity(value);
            if (value) setInValidFields(omit(inValidFields, ["city"]));
          }}
          value={city}
          invalid={inValidFields.city}
        />
        <TextField
          label="Adresa"
          onChange={(value) => {
            setAddress(value);
            if (value) setInValidFields(omit(inValidFields, ["address"]));
          }}
          value={address}
          invalid={inValidFields.address}
        />
      </FieldContainer>
      <FieldContainer>
        <TextField
          label="Cod Postal"
          onChange={(value) => {
            setZip(value);
            if (value) setInValidFields(omit(inValidFields, ["zip"]));
          }}
          value={zip}
          invalid={inValidFields.zip}
        />
        <TextField
          label="Nr de Telefon"
          onChange={(value) => {
            setMobile(value);
            if (value) setInValidFields(omit(inValidFields, ["mobile"]));
          }}
          value={mobile}
          invalid={inValidFields.mobile}
        />
      </FieldContainer>
    </Modal>
  );
};
