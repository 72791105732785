import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { actionDispatch } from "../../appState/actions";
import {
  useAppDispatch,
  useAppState,
  useRequestProducts,
} from "../../appState/appState";
import { IconButton } from "../../components/Inputs/IconButton";
import { useIsUserLogged } from "../../utils";

const StyledIconButton = styled(IconButton)`
  margin-left: 20px;
`;

export const FavoriteProductsButton = ({ className }) => {
  const isUserLogged = useIsUserLogged();
  const { favorite } = useAppState().productsFilters;
  const dispatch = useAppDispatch();
  const requestProducts = useRequestProducts();

  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      requestProducts();
    }
    ref.current = true;
    // eslint-disable-next-line
  }, [favorite]);

  return isUserLogged ? (
    <StyledIconButton
      text="Selecteaza Favorite"
      icon={favorite ? "favorite-white-full" : "favorite-white"}
      className={className}
      onClick={() =>
        dispatch(actionDispatch.productsFilters.setFavorite(!favorite))
      }
    />
  ) : null;
};
