import { icons } from "./icons";
import styled from "styled-components";

export const Icon = styled.img.attrs(({ icon }) => ({
  className: "icon",
  src: icons[icon],
}))`
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
  height: ${({ size }) => `${size || 40}px`};
`;
