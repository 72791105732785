import React from "react";
import styled from "styled-components";

const Container = styled.div.attrs({ className: "edit-field" })`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled.p.attrs({ className: "edit-field__label" })`
  margin: 0;
  margin-bottom: 10px;
  font-size: 20px;
`;

const Input = styled.input.attrs({ className: "edit-field__input" })`
  border: 1px solid black;
  height: 40px;
  border-radius: 40px;
  font-size: 20px;
  outline: none;
  padding: 0px 20px;
`;

const Textarea = styled.textarea.attrs({ className: "edit-field__textarea" })`
  border: 1px solid black;
  height: 210px;
  border-radius: 40px;
  font-size: 20px;
  outline: none;
  padding: 20px 20px;
  resize: vertical;
`;

export const EditField = ({
  value,
  label,
  onChange = () => {},
  onEnter,
  textarea,
  placeholder,
}) => {
  const Field = textarea ? Textarea : Input;

  return (
    <Container>
      {label && <Label>{label}</Label>}

      <Field
        value={value}
        onChange={({ target: { value } }) => onChange(value)}
        onKeyDown={({ key }) => {
          if (key === "Enter" && onEnter) onEnter(value);
        }}
        placeholder={placeholder}
      />
    </Container>
  );
};
