import { round } from "lodash";
import React from "react";
import styled from "styled-components";
import { actionDispatch } from "../../appState/actions";
import { useAppDispatch } from "../../appState/appState";
import { Button } from "../../components/Inputs/Button";
import { IconButton } from "../../components/Inputs/IconButton";
import { colors } from "../../styles/colors";
import { formatPrice } from "./productUtils";

const Container = styled.div`
  display: flex;
  margin-top: 30px;
`;

const ValuesContainer = styled.div`
  display: flex;
  flex: 1;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  .button:last-child {
    margin-top: 16px;
  }
`;

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0px 25px;

  &:first-child {
    padding-left: 0;
    border-right: 1px solid ${colors.lightgrey};
  }

  &:last-child {
    padding-right: 0;
  }
`;

const ValueLabel = styled.h3`
  margin: 0;
  margin-bottom: 5px;
  font-size: 20px;
`;

const ValueLabelWarranty = styled(ValueLabel)`
  margin-bottom: 25px;
`;

const Value = styled.h2`
  margin: 0;
  color: ${colors.red};
  font-size: 30px;
  display: flex;

  span {
    font-size: 16px;
    margin-top: 2px;
    margin-right: 8px;
  }
`;

const FullPrice = styled.p`
  text-decoration: line-through;
  margin: 0;
`;

const useAddToCart = (item) => {
  const dispatch = useAppDispatch();

  return () => dispatch(actionDispatch.cart.addItem(item));
};

export const ProductMainInfoFooter = (props) => {
  const { warranty, price, discount } = props;
  const finalPrice = round(
    !!discount ? price - price * (discount / 100) : price,
    2
  );
  const { integer, decimals } = formatPrice(finalPrice);

  const onAddToCart = useAddToCart(props);

  return (
    <Container>
      <ValuesContainer>
        <ValueContainer>
          <ValueLabelWarranty>GARANTIE</ValueLabelWarranty>
          <Value>{warranty} Ani</Value>
        </ValueContainer>
        <ValueContainer>
          <ValueLabel>PRET</ValueLabel>
          <FullPrice>{price} Lei</FullPrice>
          <Value>
            {integer}
            <span>{decimals}</span> Lei
          </Value>
        </ValueContainer>
      </ValuesContainer>
      <ActionsContainer>
        <Button text="Adauga la Favorite" secondary />
        <IconButton
          text="Adauga in cos"
          icon="cart-white"
          onClick={onAddToCart}
        />
      </ActionsContainer>
    </Container>
  );
};
