import config from "../config";
import axios from "axios";
import emailjs from "@emailjs/browser";
import { generateAddProductPayload, getSortParam } from ".";

const serviceID = "service_06rjoip";
const templateID = "template_o707oza";
const userID = "user_xlyLUbENywvyi5Vh77nQj";

export const login = (email, password) => {
  return new Promise((res, rej) => {
    axios
      .post(`${config.domains.api}/users/login`, {
        email,
        password,
      })
      .then((rsp) => {
        if (rsp.data.token) {
          localStorage.setItem("token", rsp.data.token);
          res(rsp.data.token);
        }
      })
      .catch((err) => {
        console.log(err);
        rej(err);
      });
  });
};

export const getUserDetails = () => {
  return new Promise((res, rej) => {
    const token = localStorage.getItem("token");

    const axiosConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .get(`${config.domains.api}/users/user-data`, axiosConfig)
      .then((rsp) => {
        if (rsp.data.user) res(rsp.data.user);
      })
      .catch((err) => {
        console.log(err);
        rej(err);
      });
  });
};

export const registerUser = (payload) => {
  return new Promise((res, rej) => {
    const token = localStorage.getItem("token");

    const axiosConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .post(`${config.domains.api}/users/register`, payload, axiosConfig)
      .then((rsp) => {
        if (rsp.data.token) {
          localStorage.setItem("token", rsp.data.token);
          res(rsp.data.token);
        }
      })
      .catch((err) => {
        console.log(err);
        rej(err);
      });
  });
};

export const updateUserData = (payload) => {
  return new Promise((res, rej) => {
    const token = localStorage.getItem("token");

    const axiosConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .put(`${config.domains.api}/users/user-data`, payload, axiosConfig)
      .then((rsp) => {
        if (rsp.data) {
          console.log(rsp.data);
          res(rsp.data);
        }
      })
      .catch((err) => {
        console.log(err);
        rej(err);
      });
  });
};

export const getProducts = (lastItem, sort, filters) => {
  return new Promise((res, rej) => {
    const token = localStorage.getItem("token");

    axios
      .get(
        `${config.domains.api}/products${
          filters && filters.favorite ? "/favorite" : ""
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            last_item: !(filters && filters.favorite)
              ? lastItem && JSON.stringify(lastItem)
              : undefined,
            sort: sort && getSortParam(sort),
          },
        }
      )
      .then((rsp) => {
        if (rsp.data) {
          console.log(rsp.data);
          res(rsp.data);
        }
      })
      .catch((err) => {
        console.log(err);
        rej(err);
      });
  });
};

export const getProduct = (productId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${config.domains.api}/products/${productId}`)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const addFavoriteProductToUser = (productId) => {
  return new Promise((res, rej) => {
    const token = localStorage.getItem("token");

    const axiosConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .put(
        `${config.domains.api}/users/favorite-product`,
        { id: productId },
        axiosConfig
      )
      .then((rsp) => {
        if (rsp.data) {
          console.log(rsp.data);
          res(rsp.data);
        }
      })
      .catch((err) => {
        console.log(err);
        rej(err);
      });
  });
};

export const removeFavoriteProductFromUser = (productId) => {
  return new Promise((res, rej) => {
    const token = localStorage.getItem("token");

    const axiosConfig = {
      headers: { Authorization: `Bearer ${token}` },
      data: { id: productId },
    };

    axios
      .delete(`${config.domains.api}/users/favorite-product`, axiosConfig)
      .then((rsp) => {
        if (rsp.data) {
          console.log(rsp.data);
          res(rsp.data);
        }
      })
      .catch((err) => {
        console.log(err);
        rej(err);
      });
  });
};

export const addProduct = (data) => {
  const payload = generateAddProductPayload(data);

  return new Promise((res, rej) => {
    const token = localStorage.getItem("token");

    const axiosConfig = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .post(`${config.domains.api}/products`, payload, axiosConfig)
      .then((rsp) => {
        if (rsp.data) {
          console.log(rsp.data);
          res(rsp.data);
        }
      })
      .catch((err) => {
        console.log(err);
        rej(err);
      });
  });
};

export const editProduct = (data) => {
  const payload = generateAddProductPayload(data);

  return new Promise((res, rej) => {
    const token = localStorage.getItem("token");

    const axiosConfig = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .put(`${config.domains.api}/products`, payload, axiosConfig)
      .then((rsp) => {
        if (rsp.data) {
          console.log(rsp.data);
          res(rsp.data);
        }
      })
      .catch((err) => {
        console.log(err);
        rej(err);
      });
  });
};

export const getFiltersByType = (type) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${config.domains.api}/filters?type=${type}`)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const getSuggestedProducts = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${config.domains.api}/products?suggested=true`)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const getPromoProduct = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${config.domains.api}/products?promo=true`)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const sendEmail = ({ name, email, subject, message }) => {
  return new Promise((res, rej) => {
    const templateParams = {
      name,
      mail: email,
      subject,
      message,
      to_email: "ayotek.service@gmail.com",
    };

    emailjs
      .send(serviceID, templateID, templateParams, userID)
      .then(() => {
        res();
      })
      .catch((error) => {
        console.log(error);
        rej();
      });
  });
};

export const placeOrder = ({ details, products, value }) => {
  return new Promise((res, rej) => {
    const token = localStorage.getItem("token");

    const axiosConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .post(
        `${config.domains.api}/orders`,
        { details, products, value },
        axiosConfig
      )
      .then((rsp) => {
        if (rsp.data) {
          console.log(rsp.data);
          res(rsp.data);
        }
      })
      .catch((err) => {
        console.log(err);
        rej(err);
      });
  });
};

export const getOrdersByUser = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("token");
    const axiosConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .get(`${config.domains.api}/orders`, axiosConfig)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};
