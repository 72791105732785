import React from "react";
import styled from "styled-components";
import { useAppState } from "../../appState/appState";
import { SortDropdown } from "../../components/SortDropdown/SortDropdown";
import { AddProductsButton } from "./AddProductsButton";
import { FavoriteProductsButton } from "./FavoriteProductsButton";

const Container = styled.div.attrs({ className: "products-list-header" })`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ProductsCount = styled.h3.attrs({ className: "products-list-header" })`
  margin: 0px;
  margin-top: 10px;
`;

const getText = (products, favorite) => {
  if (favorite) {
    return products.length
      ? `Produse favorite (${products.length})`
      : "Nu exista produse favorite";
  }

  return "Toate Produsele";
};

export const ProductsListHeader = () => {
  const {
    products,
    productsFilters: { favorite },
  } = useAppState();
  const productsCount = getText(products, favorite);

  return (
    <Container>
      <ProductsCount>{productsCount}</ProductsCount>
      <SortDropdown />
      <FavoriteProductsButton />
      <AddProductsButton />
    </Container>
  );
};
