import React from "react";
import styled from "styled-components";
import { useAppState } from "../../appState/appState";
import { ProductItem } from "./ProductItem";
import { ProductsListHeader } from "./ProductsListHeader";
import { LoadMoreProductsButton } from "./LoadMoreProductsButton";
import { SmallLoading } from "../../components/SmallLoading";
import { useIsFavoriteProduct } from "../Product/ProductHooks";

const Container = styled.div.attrs({ className: "products-list-container" })`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 20px;

  .product-list__product-item {
    margin-bottom: 20px;
  }
`;

export const ProductsList = ({ loading }) => {
  const products = useAppState().products;
  const isFavoriteProduct = useIsFavoriteProduct();

  return (
    <Container>
      <ProductsListHeader />
      {loading ? (
        <SmallLoading />
      ) : (
        <>
          {products.map((item) => (
            <ProductItem
              key={item.id}
              {...item}
              favoriteProduct={isFavoriteProduct(item.id)}
            />
          ))}
          <LoadMoreProductsButton />
        </>
      )}
    </Container>
  );
};
