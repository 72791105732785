import React from "react";
import styled from "styled-components";
import { CartItems } from "./CartItems";
import { CartTotalPrice } from "./CartTotalPrice";
import { PlaceOrderButton } from "./PlaceOrderButton";

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px 40px;
  padding-top: 30px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
`;

const Title = styled.h2`
  text-align: center;
`;

export const CartContent = () => {
  return (
    <Container>
      <Title>Cosul Tau</Title>
      <CartItems />
      <CartTotalPrice />
      <PlaceOrderButton />
    </Container>
  );
};
