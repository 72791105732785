import React, { useEffect } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import { actionDispatch } from "../../../appState/actions";
import { useAppDispatch, useAppState } from "../../../appState/appState";
import { Dropdown } from "../../../components/Dropdown/Dropdown";
import { Checkbox } from "../../../components/Inputs/Checkbox";
import { CheckboxArray } from "../../../components/Inputs/CheckboxArray";
import { EditField } from "../../../components/Inputs/EditField";
import { ProductImages } from "../../../components/ProductImages/ProductImages";
import { Page } from "../../Page";
import {
  useGetCategoryOptions,
  useGetProductData,
  useGetBrandOptions,
} from "./EditProductHooks";
import { EditProductsAction } from "./EditProdutcsAction";
import { EditSpecs } from "./EditSpecs";

const STOCK_OPTIONS = ["In stoc", "Indisponibil", "Pe comanda"];

const EditProductsPage = styled(Page).attrs({
  className: "add-products-page",
})`
  .edit-field {
    margin-bottom: 40px;
  }
`;

const SectionRow = styled.div.attrs({ className: "add-products__section-row" })`
  display: flex;
  flex: 1;
`;

const SectionColumn = styled.div.attrs({
  className: "add-products__section-column",
})`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const PromoSection = styled.div.attrs({
  className: "add-products__promo-section",
})`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`;

const EditSection = styled(SectionColumn).attrs({
  className: "add-products__edit-section",
})`
  padding-left: 20px;
`;

const ConstantFieldsSection = styled(SectionRow)`
  align-items: center;
  justify-content: flex-start;
  flex: 0;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }

  .dropdown:first-child {
    margin-right: 20px;
  }

  .edit-field {
    flex: 1;
    margin-bottom: 0;

    &:first-child {
      margin-right: 20px;
    }
  }
`;

const EmptyBox = styled.div`
  flex: 1;
  margin-right: 20px;
`;

const handleOnNameChange = (dispatch) => (value) => {
  dispatch(actionDispatch.editProduct.setField("name", value));
};

const handleOnDescriptionChange = (dispatch) => (value) => {
  dispatch(actionDispatch.editProduct.setField("description", value));
};

const handleOnPromoProductChange = (dispatch) => (value) => {
  dispatch(actionDispatch.editProduct.setField("promoProduct", value));
};

const handleSuggestedProductChange = (dispatch) => (value) => {
  dispatch(actionDispatch.editProduct.setField("suggestedProductIndex", value));
};

const handleCategoryChange = (dispatch) => (value) => {
  dispatch(actionDispatch.editProduct.setField("category", value));
};

const handleBrandChange = (dispatch) => (value) => {
  dispatch(actionDispatch.editProduct.setField("brand", value));
};

const handlePriceChange = (dispatch) => (value) => {
  dispatch(actionDispatch.editProduct.setField("price", value));
};

const handleStockStatusChange = (dispatch) => (value) => {
  dispatch(actionDispatch.editProduct.setField("stockStatus", value));
};

const handleDiscountChange = (dispatch) => (value) => {
  dispatch(actionDispatch.editProduct.setField("discount", value));
};

const handleWarrantyChange = (dispatch) => (value) => {
  dispatch(actionDispatch.editProduct.setField("warranty", value));
};

const handleStockValueChange = (dispatch) => (value) => {
  dispatch(actionDispatch.editProduct.setField("stockValue", value));
};

export const EditProducts = ({ addProducts }) => {
  useGetProductData(addProducts);
  const { state } = useLocation();

  const {
    name,
    description,
    promoProduct,
    suggestedProductIndex,
    category,
    brand,
    stockStatus,
    stockValue,
    price,
    discount,
    warranty,
    imgs,
  } = useAppState().editProduct;
  const dispatch = useAppDispatch();

  const { categoryOptions } = useGetCategoryOptions();
  const { brandOptions } = useGetBrandOptions();

  useEffect(() => {
    if (addProducts) {
      if (state?.copy) {
        dispatch(actionDispatch.editProduct.removeId());
      } else {
        dispatch(actionDispatch.editProduct.clear());
      }
    }
  }, [addProducts, dispatch, state]);

  return (
    <EditProductsPage>
      <SectionRow>
        <ProductImages imgs={imgs} />
        <EditSection>
          <EditField
            label="Denumire"
            value={name || ""}
            onChange={handleOnNameChange(dispatch)}
          />
          <EditField
            label="Descriere"
            value={description}
            onChange={handleOnDescriptionChange(dispatch)}
            textarea
          />
          <PromoSection>
            <Checkbox
              value={promoProduct}
              label="Produs Promotional"
              onChange={handleOnPromoProductChange(dispatch)}
            />
            <CheckboxArray
              position={suggestedProductIndex}
              numOfElements={4}
              label="Ordine produse sugerate"
              onChange={handleSuggestedProductChange(dispatch)}
            />
          </PromoSection>
          <ConstantFieldsSection>
            <Dropdown
              label="Categorii"
              selectedValue={category}
              options={categoryOptions}
              onSelectItem={handleCategoryChange(dispatch)}
              addField
            />
            <Dropdown
              label="Brand"
              selectedValue={brand}
              options={brandOptions}
              onSelectItem={handleBrandChange(dispatch)}
              addField
            />
          </ConstantFieldsSection>
          <ConstantFieldsSection>
            <EditField
              label="Pret"
              value={price}
              onChange={handlePriceChange(dispatch)}
            />
            <Dropdown
              label="Stoc"
              selectedValue={stockStatus}
              options={STOCK_OPTIONS}
              onSelectItem={handleStockStatusChange(dispatch)}
            />
          </ConstantFieldsSection>
          <ConstantFieldsSection>
            <EditField
              label="Reducere"
              value={discount}
              onChange={handleDiscountChange(dispatch)}
            />
            <EditField
              label="Garantie"
              value={warranty}
              onChange={handleWarrantyChange(dispatch)}
            />
          </ConstantFieldsSection>

          <ConstantFieldsSection>
            <EmptyBox />
            <EditField
              label="Nr. produse"
              value={stockValue}
              onChange={handleStockValueChange(dispatch)}
            />
          </ConstantFieldsSection>
        </EditSection>
      </SectionRow>
      <EditSpecs />
      <EditProductsAction />
    </EditProductsPage>
  );
};
