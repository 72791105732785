import React from "react";
import styled from "styled-components";
import { FilterBrand } from "./FilterBrand";
import { FilterCategory } from "./FilterCategory";
import { FilterType } from "./FilterType";
import { FilterPrice } from "./FilterPrice";
import { ResetFiltersButton } from "./ResetFiltersButton";

const Container = styled.div.attrs({ className: "filters-container" })`
  display: flex;
  flex-direction: column;

  .filter-box-container {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

export const Filters = () => {
  return (
    <Container>
      <ResetFiltersButton />
      <FilterCategory />
      <FilterType />
      <FilterBrand />
      <FilterPrice />
    </Container>
  );
};
