import React from "react";
import styled from "styled-components";
import { colors } from "../../../styles/colors";

const Container = styled.div.attrs({
  className: "filter-box-container",
})`
  width: 160px;
  padding: 25px 35px;
  background: ${colors.lightgrey};
  border-radius: 20px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);

  .checkbox {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: unset;
    }
  }
`;

const Title = styled.h3.attrs({ className: "filter-box-title" })`
  margin: 0;
  margin-bottom: 20px;
`;

export const FilterBox = ({ title, children }) => {
  return (
    <Container>
      <Title>{title}</Title>
      {children}
    </Container>
  );
};
