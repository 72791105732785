import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Icon } from "../../components/Icon/Icon";
import { Button } from "../../components/Inputs/Button";
import { colors } from "../../styles/colors";

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;

  .icon {
    height: 100px;
  }
`;

const InnerContainer = styled.div`
  background: ${colors.green};
  color: white;
  flex: 1;
  max-width: 1000px;
  margin: 50px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;

  .button {
    margin-top: auto;
  }
`;

const Title = styled.h1`
  font-size: 60px;
  width: 500px;
  text-align: center;
`;

const SubTitle = styled.p`
  margin-bottom: 50px;
`;

export const OrderSent = () => {
  const history = useHistory();

  return (
    <Container>
      <InnerContainer>
        <Icon icon="happy" />
        <Title>COMANDA FINALIZATA</Title>
        <SubTitle>
          Iti multumim ca ai comandat de la noi, in scurt timp vei fi contactat
          de o persoana de la noi pentru a stabili detaliile de livrare.
        </SubTitle>
        <Button
          secondary
          text="Inapoi la pagina principala"
          onClick={() => history.push("/")}
        />
      </InnerContainer>
    </Container>
  );
};
