export const formatPrice = (price) => {
  if (price) {
    const [integer, decimals] = `${price}`.split(".");

    const integerArray = integer.split("");
    let formatInteger = [];

    for (let i = 0; i < integerArray.length; i++) {
      const digit = integerArray[integerArray.length - 1 - i];
      if (i !== 0 && i % 3 === 0) formatInteger.push(".");
      formatInteger.push(digit);
    }

    formatInteger.reverse();

    return {
      integer: formatInteger.join(""),
      decimals,
    };
  }

  return {
    integer: 0,
    decimals: 0,
  };
};
