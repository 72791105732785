import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { actionDispatch } from "../../appState/actions";
import { useAppDispatch, useAppState } from "../../appState/appState";
import { useClickOutside } from "../../utils";
import { Icon } from "../Icon/Icon";
import { CartBanner } from "./CartBanner";
import { CartContent } from "./CartContent";

const appearAnimationOverlay = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

const appearAnimationContainer = keyframes`
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
`;

const hideAnimationOverlay = keyframes`
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
`;

const hideAnimationContainer = keyframes`
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: 2;
  animation: ${({ closeAnimation }) =>
      closeAnimation ? hideAnimationOverlay : appearAnimationOverlay}
    0.3s ease forwards;
`;

const CartContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 800px;
  background: white;
  animation: ${({ closeAnimation }) =>
      closeAnimation ? hideAnimationContainer : appearAnimationContainer}
    0.3s ease forwards;

  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  overflow: hidden;
`;

const useOnCloseCart = () => {
  const dispatch = useAppDispatch();

  return () => {
    dispatch(actionDispatch.cart.setVisibility(false));
  };
};

const useHideCart = () => {
  const dispatch = useAppDispatch();

  return () => dispatch(actionDispatch.cart.setVisibility(false));
};

export const Cart = () => {
  const {
    cart: { visibility, items },
  } = useAppState();

  const [show, setShow] = useState(visibility);
  const [closeAnimation, setCloseAnimation] = useState(false);
  const didMountRef = useRef(false);
  const onCloseCart = useOnCloseCart();
  const ref = useClickOutside(visibility ? onCloseCart : undefined);
  const onHideCart = useHideCart();

  useEffect(() => {
    if (didMountRef.current) {
      if (!visibility) {
        setCloseAnimation(true);
        setTimeout(() => {
          setShow(false);
          setCloseAnimation(false);
        }, 350);
      } else {
        setShow(true);
      }
    }
    didMountRef.current = true;
    // eslint-disable-next-line
  }, [visibility]);

  useEffect(() => {
    if (didMountRef.current && items.length === 0) {
      onHideCart();
    }
    // eslint-disable-next-line
  }, [items.length]);

  return show ? (
    <Container closeAnimation={closeAnimation}>
      <CartContainer ref={ref} closeAnimation={closeAnimation}>
        <Icon icon="close" className="close-icon" onClick={onCloseCart} />
        <CartContent />
        <CartBanner />
      </CartContainer>
    </Container>
  ) : null;
};
