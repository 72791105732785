import React from "react";
import styled from "styled-components";
import { ProductsBanner } from "../Products/ProductsBanner";
import { OrderProcess } from "../../components/OrderProcess";
import { PromoProduct } from "./PromoProduct";
import { SuggestedProducts } from "./SuggestedProducts";

const HomeContainer = styled.div.attrs({ className: "home-page" })`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Home = () => (
  <HomeContainer>
    <ProductsBanner homeBanner pageTitle="Produse" />
    <SuggestedProducts />
    <PromoProduct />
    <OrderProcess />
  </HomeContainer>
);
